import { SearchOutlined } from "@ant-design/icons";
import "./search.scss";
import React, { useEffect, useRef, useState, useContext } from "react";
import AiSearchInput from "../../components/AiSearchInput/AiSearchInput";
import queryString from "query-string";
import { aiApi } from "../../config/aiApi";
import { pages } from "../../constants/PagePaths";
import { useHistory } from "react-router-dom";
import { Cookies } from "../../constants/Cookies";
import { util } from "../../config/util";
import SearchResultAside from "../searchResult/SearchResultAside";
import AlertContext from "../../contexts/AlertContext";
import { QuotaInfo } from "../../models/quotaModel";

export default function Search() {
  const alertContext = useContext(AlertContext);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const [historyConversation, setHistoryConversation] = useState([]);
  const [searchCategory, setSearchCategory] = useState([
    {
      title: "일위대가 자동생성",
      active: true,
      api: "claude_response_part1",
    },
    {
      title: "기본 웹 검색",
      active: false,
      api: "perplexity",
    },

    // {
    //   title: "건설 품셈 도우미",
    //   active: false,
    // },
  ]);
  const [quota, setQuota] = useState<QuotaInfo>({
    perplexity: "",
    claude_response_part1: "",
    claude_response_part2: "",
    analyze_project_statements: "",
    project_recommendation: "",
  });
  useEffect(() => {
    const getQuota = async () => {
      const quota = await util.getQuota();
      setQuota(quota);
    };

    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (stickyTopElement && searchContainerRef.current) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchContainerRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    const getHistoryConversation = async () => {
      if (util.getCookie(Cookies.authKey)) {
        const res = await aiApi.getConversation();
        console.log("res", res);
        setHistoryConversation(res.conversations);
      }
    };
    getQuota();
    getHistoryConversation();
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  async function onSearchSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent,
    result: string
  ) {
    const key = util.getCookie(Cookies.authKey);
    const activeCategory = searchCategory.find((category) => category.active);

    if (!activeCategory) {
      alertContext.showAlert("검색 카테고리를 선택해주세요.");
      return;
    }

    try {
      switch (activeCategory.title) {
        case "기본 웹 검색":
          if (!key) {
            const param = queryString.stringify({
              value: result,
            });
            history.push(`${pages.searchResult}?${param}`);
          } else {
            const res = await aiApi.postConversastion(result, "search");
            await aiApi.postMessage({
              conversation_id: res.conversation_id,
              role: "user",
              content: result,
            });
            if (res.code === "0") {
              alertContext.showAlert("오류가 발생했습니다.");
              return;
            }
            const param = queryString.stringify({
              seq: res.conversation_id,
            });
            history.push(`${pages.searchResult}?${param}`);
          }
          break;

        case "일위대가 자동생성":
          if (!key) {
            const param = queryString.stringify({
              value: result,
            });
            history.push(`${pages.statementResult}?${param}`);
          } else {
            const res = await aiApi.postConversastion(result, "statement");
            await aiApi.postStatement({
              content: result,
              role: "user",
              conversation_id: res.conversation_id,
            });
            const param = queryString.stringify({
              id: res.conversation_id,
            });
            history.push(`${pages.statementResult}?${param}`);
          }
          break;

        case "건설 품셈 도우미":
          console.log("건설 품셈 도우미");
          break;

        default:
          alertContext.showAlert("지원하지 않는 검색 카테고리입니다.");
          break;
      }
    } catch (error) {
      console.error(error);
      alertContext.showAlert(
        "오류가 발생했습니다. 고객센터를 통해 문의해 주세요."
      );
    }
  }
  function handleSearchCategoryClick(index: number) {
    const newSearchCategory = searchCategory.map((item, idx) => ({
      ...item,
      active: idx === index,
    }));
    setSearchCategory(newSearchCategory);
  }
  return (
    <section className="search-container">
      <div className="overlay"></div>
      <SearchResultAside />
      <div className="search-content" ref={searchContainerRef}>
        <h1>
          건설업의 미래가 시작되는 곳 <b>콘엑스 AI</b>
        </h1>
        <div className="search-category">
          {searchCategory.map((item, index) => (
            <button
              className={`search-category-item ${item.active ? "active" : ""}`}
              onClick={() => handleSearchCategoryClick(index)}
            >
              <span className="search-category-item-text">{item.title}</span>
            </button>
          ))}
        </div>
        <AiSearchInput
          loading={false}
          onSearchSubmit={onSearchSubmit}
          tooltipText="현재 무료로 PRO 기능을 이용중입니다<br>최적화된 답변을 받을 수 있습니다."
          searchCategory={searchCategory}
          quota={quota}
        />
      </div>
    </section>
  );
}
