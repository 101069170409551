import axios from "axios";
import { aiApiKey } from "../models/apiKey";
import GlobalEnvironments from "../modules/globalEnvironment/GlobalEnvironment";
import { util } from "./util";
import { Cookies } from "../constants/Cookies";
import { Message } from "../models/aiSearchModel";
import { param } from "jquery";
import { getQuote } from "html2canvas/dist/types/css/property-descriptors/quotes";

export const aiApi = {
  chatCompletions: async (messages: Array<Message>) => {
    try {
      const config = await axios.get(
        `${GlobalEnvironments.getApiUrl()}dk_ai/config/`
      );

      const query = {
        model: config.data.name,
        max_tokens: config.data.token,
        messages,
      };
      const quota = await aiApi.getQuota("perplexity");

      const response = await fetch(
        "https://api.perplexity.ai/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: aiApiKey,
          },
          body: JSON.stringify(query),
        }
      );

      if (!response.ok) {
        throw new Error("API 요청 실패", { cause: response });
      }

      const result = await response.json();
      const key = util.getCookie(Cookies.authKey);
      const userConfig = {
        headers: {
          Authorization: `Token ${key}`,
        },
      };
      const param = {
        input_tokens: result.usage?.prompt_tokens,
        output_tokens: result.usage?.completion_tokens,
        total_tokens: result.usage?.total_tokens,
        query: messages.map((message) => message.content).join(""),
        type: "perplexity",
      };
      const usage = await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/usage/`,
        param,
        userConfig
      );
      // 토큰 사용량 로깅
      console.log("토큰 사용 통계:", usage.data);
      return result;
    } catch (error: any) {
      if (error.response.status === 403) {
        return {
          messages:
            "일일/월간 사용량을 초과하였습니다.\n고객센터로 문의해주세요.",
        };
      }
      throw error;
    }
  },
  getLinkPreview: async (url: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/crawl-og-tags`, {
        params: {
          url,
        },
        ...config,
      })
    ).data;
  },
  postMessage: async ({ ...args }: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const data = {
      ...args,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/message/`,
        data,
        config
      )
    ).data;
  },
  getMessage: async (seq: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        conversation_id: seq,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/message`, {
        ...config,
      })
    ).data;
  },

  getConversation: async (type?: "search" | "statement") => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        type,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/conversation`, {
        ...config,
      })
    ).data;
  },
  postConversastion: async (title: string, type: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const data = {
      title,
      type,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/conversation/`,
        data,
        config
      )
    ).data;
  },
  getCompletion: async (message_id: string) => {
    console.log("message_id", message_id);
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/completion/`, {
        params: {
          message_id,
        },
        ...config,
      })
    ).data;
  },
  postCompletion: async (completion: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const data = {
      ...completion,
    };
    console.log("data", data);
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/completion/`,
        data,
        config
      )
    ).data;
  },

  getStateCompletion: async (request: string) => {
    try {
      const key = util.getCookie(Cookies.authKey);
      const config = {
        headers: {
          Authorization: `Token ${key}`,
        },
      };
      const query = {
        query: request,
      };
      const claude_response_part1_quota = await aiApi.getQuota(
        "claude_response_part1"
      );
      if (claude_response_part1_quota.code === 2) {
        return {
          messages: claude_response_part1_quota.message,
        };
      }
      const claude_response_part2_quota = await aiApi.getQuota(
        "claude_response_part2"
      );
      if (claude_response_part2_quota.code === 2) {
        return {
          messages: claude_response_part2_quota.message,
        };
      }
      return (
        await axios.post(
          `${GlobalEnvironments.getApiUrl()}ai/itemized/search/`,
          query,
          config
        )
      ).data;
    } catch (error: any) {
      if (error.response.status === 403) {
        return {
          messages:
            "일일/월간 사용량을 초과하였습니다.\n고객센터로 문의해주세요.",
        };
      }
      throw error;
    }
  },
  getStateExcel: async (request: {
    itemized_cost_ids: number[];
    price_calculation_ids: number[];
  }) => {
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}ai/itemized/excel/`,
        request
      )
    ).data;
  },

  getStatement: async (conversation_id: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/statement/`, {
        params: {
          conversation_id,
        },
        ...config,
      })
    ).data;
  },

  postStatement: async (data: {
    content: string;
    role: string;
    conversation_id: string;
    itemized_cost_id?: string;
    itemized_cost_titles?: string;
    price_calculation_id?: string;
    price_calculation_titles?: string;
    itemized_has_project?: string;
    price_has_project?: string;
    labels?: string;
  }) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/statement/`,
        {
          content: data.content,
          role: data.role,
          conversation_id: data.conversation_id,
          itemized_cost_id: data.itemized_cost_id?.toString(),
          itemized_cost_titles: data.itemized_cost_titles?.toString(),
          price_calculation_id: data.price_calculation_id?.toString(),
          price_calculation_titles: data.price_calculation_titles?.toString(),
          itemized_has_project: data.itemized_has_project?.toString(),
          price_has_project: data.price_has_project?.toString(),
          labels: data.labels?.toString(),
        },
        config
      )
    ).data;
  },

  getProjectPreview: async (
    id?: string,
    name?: string,
    type?: "price_calculation" | "itemized"
  ) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const query = {
      item_id: id,
      item_name: name,
      item_type: type,
    };
    return (
      await axios.get(
        `${GlobalEnvironments.getApiUrl()}ai/project/connections`,
        {
          params: query,
          ...config,
        }
      )
    ).data;
  },
  getitemizedToPdf: async (itemized_cost_id: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const query = {
      itemized_id: itemized_cost_id,
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}ai/itemized/pdf/`, {
        params: query,
        ...config,
      })
    ).data;
  },
  getPdfDownload: async (url: string) => {
    return axios.get(url, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
  },
  getStatementDescription: async (statement_id: string, label: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/description/`, {
        params: {
          statement_id,
          label,
        },
        ...config,
      })
    ).data;
  },
  postStatementDescription: async (data: {
    label: string;
    description: string;
    statement_id: string;
  }) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const query = {
      label: data.label,
      description: data.description,
      statement_id: data.statement_id,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/description/`,
        query,
        config
      )
    ).data;
  },
  getStatementCitations: async (statement_id: string, label: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/citation/`, {
        params: {
          statement_id,
          label,
        },
        ...config,
      })
    ).data;
  },
  postStatementCitations: async (data: { citations: any }) => {
    const key = util.getCookie(Cookies.authKey);
    const param = {
      id: data.citations.id,
      label: data.citations.label,
      title: data.citations.title,
      description: data.citations.description,
      url: data.citations.url,
      image_url: data.citations.image_url,
      statement_id: data.citations.statement_id,
    };
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/citation/`,
        param,
        config
      )
    ).data;
  },
  submitFeedbackContent: async (data: {
    conversation_id: string;
    message_index: number;
    feedback_type: string;
    feedback_content: string;
    type: "statement" | "chat";
  }) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/feedback/submit/`,
        {
          ...data,
        },
        config
      )
    ).data;
  },
  submitFeedbackType: async (data: {
    conversation_id: string;
    message_index: number;
    feedback_type: string;
    type: "statement" | "chat";
  }) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/feedback/quick/`,
        {
          ...data,
        },
        config
      )
    ).data;
  },
  getFeedbacks: async (conversation_id: string, type: "statement" | "chat") => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/feedback/`, {
        params: {
          conversation_id,
          type,
        },
        ...config,
      })
    ).data;
  },
  getQuota: async (type?: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/quota/`, {
        params: {
          type,
        },
        ...config,
      })
    ).data;
  },
  getQuotaInquiry: async () => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/quota/`, {
        params: {
          inquiry: true,
        },
        ...config,
      })
    ).data;
  },
};
