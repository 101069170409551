import "../searchResult.scss";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import AiSearchInput from "../../../components/AiSearchInput/AiSearchInput";
import SearchResultAside from "../SearchResultAside";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { aiApi } from "../../../config/aiApi";
import {
  LoadingOutlined,
  SearchOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import MarkdownTyper from "../../../components/MarkdownTyper";
import StatementCitations from "./statementCitations";
import TableTyper from "../../../components/TableTyper/TableTyper";
import ProjectTyper from "../../../components/ProjectTyper/ProjectTyper";
import ItemizedTyper from "../../../components/itemizedTyper/ItemizedTyper";
import { QuotaInfo } from "../../../models/quotaModel";
import { util } from "../../../config/util";
import { Form, Input, Modal, Button } from "antd";
import AlertContext from "../../../contexts/AlertContext";
import { LoadingContext } from "../../../contexts/LoadingContext";

interface UserStatement {
  conversation_id?: string;
  isNew?: boolean;
  price_id?: string[] | string;
  itemized_id?: string[] | string;
  price_titles?: string[] | string;
  itemized_titles?: string[] | string;
  id: number;
  user_id: number;
  content: string;
  role: string;
  created_at?: string;
}

interface CheckedItem {
  id: string;
  type: "itemized" | "price";
}

export default function StatementResult() {
  const location = useLocation();
  const [labels, setLabels] = useState<any[][]>([]);
  const [projectPreview, setProjectPreview] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<any[]>([]);

  const [userStatement, setUserStatement] = useState<UserStatement[]>([]);
  const [assistantStatement, setAssistantStatement] = useState<UserStatement[]>(
    []
  );
  const searchResultRef = useRef<HTMLDivElement | null>(null);
  const [projectData, setProjectData] = useState<any[]>([]);
  const [conversationId, setConversationId] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [priceData, setPriceData] = useState<any[]>([]);
  const [isPreview, setIsPreview] = useState<{ [key: number]: boolean }>({});
  const [itemizedData, setItemizedData] = useState<any[]>([]);
  const [descriptions, setDescriptions] = useState<any[]>([]);
  const [citations, setCitations] = useState<any[][]>([]);
  const [hideProjectList, setHideProjectList] = useState<{
    [key: number]: boolean;
  }>({});
  const alertContext = useContext(AlertContext);
  const loadingContexts = useContext(LoadingContext);
  const [searchCategory, setSearchCategory] = useState<
    {
      title: string;
      active: boolean;
      api: string;
    }[]
  >([]);
  const [quota, setQuota] = useState<QuotaInfo>({
    perplexity: "",
    claude_response_part1: "",
    claude_response_part2: "",
    analyze_project_statements: "",
    project_recommendation: "",
  });
  const [visibleItemizedIndex, setVisibleItemizedIndex] = useState<
    number | null
  >(null);
  const [messageFeedbacks, setMessageFeedbacks] = useState<
    Array<{
      message_index: number;
      feedback_type: string;
      has_submitted: boolean;
    }>
  >([]);
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<
    "좋아요" | "별로에요" | null
  >(null);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number | null>(
    null
  );
  const [form] = Form.useForm();
  const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([]);
  const [resetCheckedItems, setResetCheckedItems] = useState(false);

  const joinTexts = (name: string, standard: string) => {
    return standard ? `${name} - ${standard}` : name;
  };

  const writeData = useCallback(
    async (content: string) => {
      if (!content) return;

      console.log("content", content);
      if (conversationId) {
        try {
          const completion = await aiApi.getStateCompletion(content);
          if (completion.messages) {
            alert(completion.messages);
            return;
          }

          const itemized = completion.itemized_costs
            ? completion.itemized_costs.map((item: any) => item.id)
            : [];
          const price = completion.calculate_objects
            ? completion.calculate_objects.map((item: any) => item.id)
            : [];

          setPriceData(completion.calculate_objects);

          const currentLabels = completion.itemized_costs
            ? completion.itemized_costs.map((item: any) => item.note || "")
            : [];

          setLabels((prev) => {
            const newLabels = [...prev];
            newLabels[prev.length] = currentLabels;
            return newLabels;
          });
          const postRes = await aiApi.postStatement({
            content: `<p style="font-weight: bold; margin-bottom: 0; font-size: 16px;">"${content}"에 대한 답변 결과입니다.</p>`,
            role: "assistant",
            conversation_id: conversationId,
            itemized_cost_id: itemized.toString(),
            itemized_cost_titles: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            price_calculation_id: price.toString(),
            price_calculation_titles: completion.calculate_objects
              ? completion.calculate_objects.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_has_project: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) => item.has_projects)
              : [],
            price_has_project: completion.calculate_objects
              ? completion.calculate_objects.map(
                  (item: any) => item.has_projects
                )
              : [],
            labels: currentLabels,
          });
          const previewsData = await Promise.all(
            [...new Set([...Object.values(completion.citations).flat()])].map(
              async (value: unknown) => {
                if (typeof value === "string") {
                  const preview = await aiApi.getLinkPreview(value);
                  const label =
                    Object.entries(completion.citations).find(
                      ([_, urls]: [any, any]) => urls.includes(value)
                    )?.[0] || null;

                  return {
                    url: value,
                    ...preview,
                    label,
                  };
                }
                return null;
              }
            )
          );
          const descriptionPromises = completion.descriptions
            ? Object.entries(completion.descriptions).map(
                async ([label, description]) => {
                  const descriptionRes = await aiApi.postStatementDescription({
                    label,
                    description: description?.toString() || "",
                    statement_id: postRes.statement.id,
                  });
                  return descriptionRes;
                }
              )
            : [];

          // Promise.all을 사용하여 모든 description 요청 완료 대기
          await Promise.all(descriptionPromises);

          const prev = previewsData.map((item, index) => {
            const tags = item.tags || {};
            return {
              label: item.label,
              statement_id: postRes.statement.id,
              title: tags.og_title || tags.title || null,
              description: tags.og_description || tags.description || null,
              url: item.url || null,
              img: tags.og_image || tags.image || null,
            };
          });
          console.log("prev", prev);
          const citationsPromises = prev.map((citation) => {
            return aiApi.postStatementCitations({
              citations: {
                id: citation.statement_id,
                label: citation.label,
                title: citation.title,
                description: citation.description,
                url: citation.url,
                image_url: citation.img,
                statement_id: postRes.statement.id,
              },
            });
          });

          const citationRes = await Promise.all(citationsPromises);
          console.log("citationRes", citationRes);

          setAssistantStatement((prev) => [
            ...prev,
            {
              id: postRes.statement.id,
              user_id: postRes.statement.user_id,
              content: postRes.statement.content,
              role: "assistant",
              isNew: true,
              price_calculation_id: price.toString(),
              itemized_cost_id: itemized.toString(),
              price_calculation_titles: completion.calculate_objects
                ? completion.calculate_objects.map((item: any) =>
                    joinTexts(item.name, item.standard.trim())
                  )
                : [],
              itemized_cost_titles: completion.itemized_costs
                ? completion.itemized_costs.map((item: any) =>
                    joinTexts(item.name, item.standard.trim())
                  )
                : [],
            },
          ]);
          setSearchResult((prev) => [
            ...prev,
            {
              content: "asdf",
              role: "assistant",
              isNew: true,
              price_calculation_id: price.toString(),
              itemized_cost_id: itemized.toString(),
              price_calculation_titles: completion.calculate_objects
                ? completion.calculate_objects.map((item: any) =>
                    joinTexts(item.name, item.standard.trim())
                  )
                : [],
              itemized_cost_titles: completion.itemized_costs
                ? completion.itemized_costs.map((item: any) =>
                    joinTexts(item.name, item.standard.trim())
                  )
                : [],
              itemized_has_project: completion.itemized_costs
                ? completion.itemized_costs.map(
                    (item: any) => item.has_projects
                  )
                : [],
              price_has_project: completion.calculate_objects
                ? completion.calculate_objects.map(
                    (item: any) => item.has_projects
                  )
                : [],
            },
          ]);
        } catch (error) {
          setAssistantStatement((prev) => {
            return [
              ...prev,
              {
                id: 0,
                user_id: 0,
                conversation_id: conversationId,
                content: "죄송합니다. 응답을 생성하는 중 오류가 발생했습니다.",
                role: "assistant",
                isNew: false,
                price_calculation_id: [],
                itemized_cost_id: [],
                price_calculation_titles: [],
                itemized_cost_titles: [],
              },
            ];
          });
          console.log("error", error);
        } finally {
          const quota = await util.getQuota();
          setQuota(quota);
          setLoading(false);
        }
      } else {
        const completion = await aiApi.getStateCompletion(content);

        const itemized = completion.itemized_costs
          ? completion.itemized_costs.map((item: any) => item.id)
          : [];
        const price = completion.calculate_objects
          ? completion.calculate_objects.map((item: any) => item.id)
          : [];
        setPriceData(completion.calculate_objects);

        const currentLabels = completion.itemized_costs
          ? completion.itemized_costs.map((item: any) => item.note || "")
          : [];

        setLabels((prev) => {
          const newLabels = [...prev];
          newLabels[prev.length] = currentLabels;
          return newLabels;
        });

        setAssistantStatement((prev) => [
          ...prev,
          {
            id: 0,
            user_id: 0,
            content: `<p style="font-weight: bold; margin-bottom: 0; font-size: 16px;">"${content}"에 대한 답변 결과입니다.</p>`,
            role: "assistant",
            isNew: true,
            itemized_cost_id: itemized.toString(),
            itemized_cost_titles: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            price_calculation_id: price.toString(),
            price_calculation_titles: completion.calculate_objects
              ? completion.calculate_objects.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_has_project: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) => item.has_projects)
              : [],
            price_has_project: completion.calculate_objects
              ? completion.calculate_objects.map(
                  (item: any) => item.has_projects
                )
              : [],
            labels: currentLabels,
          },
        ]);
        setSearchResult((prev) => [
          ...prev,
          {
            content: "asdf",
            role: "assistant",
            isNew: true,
            price_calculation_id: price.toString(),
            itemized_cost_id: itemized.toString(),
            price_calculation_titles: completion.calculate_objects
              ? completion.calculate_objects.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_cost_titles: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_has_project: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) => item.has_projects)
              : [],
            price_has_project: completion.calculate_objects
              ? completion.calculate_objects.map(
                  (item: any) => item.has_projects
                )
              : [],
          },
        ]);
        setDescriptions((prev) => [...prev, completion.descriptions]);
        const previewsData = await Promise.all(
          [...new Set([...Object.values(completion.citations).flat()])].map(
            async (value: unknown) => {
              if (typeof value === "string") {
                const preview = await aiApi.getLinkPreview(value);
                const label =
                  Object.entries(completion.citations).find(
                    ([_, urls]: [any, any]) => urls.includes(value)
                  )?.[0] || null;

                return {
                  url: value,
                  ...preview,
                  label,
                };
              }
              return null;
            }
          )
        );
        const groupedByLabel = previewsData.reduce(
          (acc: { [key: string]: any[] }, item) => {
            if (!item) return acc;

            const tags = item?.tags || {};
            const citation = {
              statement_id: 0,
              title: tags.og_title || tags.title || null,
              description: tags.og_description || tags.description || null,
              url: item?.url || null,
              img: tags.og_image || tags.image || null,
            };

            const label = item.label || "unlabeled";
            if (!acc[label]) {
              acc[label] = [];
            }
            acc[label].push(citation);
            return acc;
          },
          {}
        );

        // 최종 형태로 변환
        const citationsList = Object.entries(groupedByLabel).map(
          ([label, citations]) => ({
            [label]: citations,
          })
        );

        setCitations((prev) => {
          const newCitations = [...prev];
          // assistantStatement.length 대신 현재 채팅의 인덱스를 사용
          const currentIndex = userStatement.length - 1;
          newCitations[currentIndex] = citationsList;
          return newCitations;
        });
      }
    },
    [conversationId, userStatement]
  );

  useEffect(() => {
    const getQuota = async () => {
      const quota = await util.getQuota();
      setQuota(quota);
      setSearchCategory([
        {
          title: "일위대가 자동생성",
          active: true,
          api: "claude_response_part1",
        },
      ]);
    };
    getQuota();
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (searchResultRef.current && stickyTopElement) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchResultRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };

    const init = async () => {
      const parsed = queryString.parse(location.search);
      const searchValue = parsed.value as string;
      const searchConversationId = parsed.id as string;
      setConversationId(searchConversationId);
      setValue(searchValue);

      try {
        if (searchConversationId) {
          setLoading(true);
          const res = await aiApi.getStatement(searchConversationId);
          console.log("res", res);
          const searchRes = res.statement
            .filter((item: any) => item.role === "assistant")
            .map((item: any) => {
              return {
                ...item,
                itemized_cost_id: Array.isArray(item.itemized_id)
                  ? item.itemized_id
                  : item.itemized_id?.split(","),
                price_calculation_id: Array.isArray(item.price_id)
                  ? item.price_id
                  : item.price_id?.split(","),
                itemized_cost_titles: Array.isArray(item.itemized_titles)
                  ? item.itemized_titles
                  : item.itemized_titles?.split(","),
                price_calculation_titles: Array.isArray(item.price_titles)
                  ? item.price_titles
                  : item.price_titles?.split(","),
                isNew: false,
                itemized_has_project: Array.isArray(item.itemized_has_project)
                  ? item.itemized_has_project
                  : item.itemized_has_project?.split(","),
                price_has_project: Array.isArray(item.price_has_project)
                  ? item.price_has_project
                  : item.price_has_project?.split(","),
                labels: item.labels?.split(","),
              };
            });

          const initialLabels = searchRes.map((item: any) => item.labels || []);
          setLabels(initialLabels);
          setAssistantStatement(searchRes);
          setSearchResult(searchRes);

          const user = res.statement.filter(
            (item: UserStatement) => item.role === "user"
          );

          if (res.statement[res.statement.length - 1].role === "user") {
            setUserStatement([
              ...user
                .slice(0, -1)
                .map((msg: any) => ({ ...msg, isNew: false })),
              { ...user[user.length - 1], isNew: true },
            ]);
          } else {
            setUserStatement(
              user.map((msg: any) => ({ ...msg, isNew: false }))
            );
          }
        } else if (searchValue) {
          setValue(searchValue);
          setUserStatement([
            {
              id: 1,
              user_id: 1,
              content: searchValue,
              role: "user",
              created_at: "2024-01-01",
              isNew: true,
            },
          ]);
          setLoading(true);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    init();
    updateHeight();
  }, [location.search]);

  useEffect(() => {
    const lastStatement = userStatement[userStatement.length - 1];
    if (lastStatement?.role === "user" && !loading && lastStatement.isNew) {
      const content = lastStatement.content;
      if (content) {
        writeData(content);
      }
    }
  }, [userStatement, writeData, loading]);

  const onSearchSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent | null,
    content: string
  ) => {
    if (loading) return;
    try {
      if (conversationId) {
        setLoading(true);
        const res = await aiApi.postStatement({
          content: content,
          role: "user",
          conversation_id: conversationId,
        });
        setUserStatement((prev) => [
          ...prev,
          {
            id: res.statement.id,
            user_id: res.statement.user_id,
            content: res.statement.content,
            role: "user",
            isNew: true,
          },
        ]);
        setValue(content);
        setLoading(false);
      } else {
        setValue(content);
        setUserStatement((prev) => [
          ...prev,
          {
            id: 0,
            user_id: 0,
            content: content,
            role: "user",
            isNew: true,
          },
        ]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const complete = (el: HTMLDivElement) => {
    window.scrollTo({
      top: el?.offsetTop - 120,
      behavior: "smooth",
    });
    setLoading(false);
  };
  const showPreview = (index: number) => {
    setIsPreview((prev) => {
      const newState = { ...prev };
      Object.keys(newState).forEach((key) => {
        newState[parseInt(key)] = false;
      });
      newState[index] = true;
      return newState;
    });
  };
  useEffect(() => {
    const array: any[] = [];
    userStatement.forEach(() => {
      array.push(null);
    });

    setItemizedData((prev) => {
      const newArray = [...array];
      // 이전 데이터 유지
      prev.forEach((item, index) => {
        if (item !== null && index < newArray.length) {
          newArray[index] = item;
        }
      });
      return newArray;
    });

    setProjectPreview((prev) => {
      const newArray = [...array];
      prev.forEach((item, index) => {
        if (item !== null && index < newArray.length) {
          newArray[index] = item;
        }
      });
      return newArray;
    });

    setProjects((prev) => {
      const newArray = [...array];
      prev.forEach((item, index) => {
        if (item !== null && index < newArray.length) {
          newArray[index] = item;
        }
      });
      return newArray;
    });
  }, [userStatement]);

  useEffect(() => {
    console.log("labels", labels);
  }, [labels]);

  // 피드백 처리 함수
  const handleFeedback = async (type: "좋아요" | "별로에요", index: number) => {
    try {
      const messageId = assistantStatement[index]?.id;

      if (!messageId) {
        console.error("메시지 ID를 찾을 수 없습니다.");
        return;
      }

      await aiApi.submitFeedbackType({
        conversation_id: conversationId,
        message_index: index,
        feedback_type: type,
        type: "statement",
      });

      setMessageFeedbacks((prev) => [
        ...prev.filter((feedback) => feedback.message_index !== index),
        {
          message_index: index,
          feedback_type: type,
          has_submitted: false,
          type: "statement",
        },
      ]);

      setSelectedFeedback(type);
      setCurrentMessageIndex(index);
      setFeedbackModalVisible(true);
    } catch (error) {
      console.error("피드백 제출 오류:", error);
    }
  };

  const handleReset = () => {
    setCheckedItems([]);
    setResetCheckedItems(true);
  };

  const handleResetComplete = () => {
    setResetCheckedItems(false);
  };

  // 피드백 제출 함수
  const handleFeedbackSubmit = async (values: any) => {
    try {
      if (currentMessageIndex !== null) {
        await aiApi.submitFeedbackContent({
          conversation_id: conversationId,
          message_index: currentMessageIndex,
          feedback_type: selectedFeedback || "",
          feedback_content: values.feedback,
          type: "statement",
        });

        setMessageFeedbacks((prev) =>
          prev.map((feedback) =>
            feedback.message_index === currentMessageIndex
              ? { ...feedback, has_submitted: true }
              : feedback
          )
        );
      }
      setFeedbackModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("피드백 내용 제출 오류:", error);
    }
  };

  // 피드백 초기화
  useEffect(() => {
    if (conversationId) {
      const initFeedbacks = async () => {
        try {
          const response = await aiApi.getFeedbacks(
            conversationId,
            "statement"
          );
          const feedbackArray = response?.feedbacks || [];

          setMessageFeedbacks(
            feedbackArray.map((feedback: any) => ({
              message_index: feedback.message_index,
              feedback_type: feedback.feedback_type,
              has_submitted: Boolean(feedback.content),
            }))
          );
        } catch (error) {
          console.error("피드백 조회 오류:", error);
        }
      };
      initFeedbacks();
    }
  }, [conversationId]);

  const handleProjectSelected = (project: any, index: number) => {
    const newArray = [...projectPreview];
    newArray[index] = project;
    setProjectPreview(newArray);

    setItemizedData((prev: any) => {
      const newData = [...prev];
      newData[index] = null;
      return newData;
    });

    showPreview(index);
  };

  const handleItemizedSelected = (data: any, index: number) => {
    setItemizedData((prev: any) => {
      const newData = Array(userStatement.length).fill(null);
      return newData.map((item, idx) => (idx === index ? data : prev[idx]));
    });

    setProjectPreview((prev: any) => {
      const newData = [...prev];
      newData[index] = null;
      return newData;
    });

    setHideProjectList((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  // 체크된 항목 관리 함수
  const handleCheckItemizedCost = (id: string, checked: boolean) => {
    setCheckedItems((prev) => {
      if (checked) {
        return [...prev, { id, type: "itemized" }];
      } else {
        return prev.filter(
          (item) => !(item.id === id && item.type === "itemized")
        );
      }
    });
  };

  const handleCheckPriceCalculation = (id: string, checked: boolean) => {
    setCheckedItems((prev) => {
      if (checked) {
        return [...prev, { id, type: "price" }];
      } else {
        return prev.filter(
          (item) => !(item.id === id && item.type === "price")
        );
      }
    });
  };

  // 엑셀 다운로드 함수
  const handleExcelDownload = async () => {
    // 중복 제거된 ID 배열 생성
    const itemizedIds = [
      ...new Set(
        checkedItems
          .filter((item) => item.type === "itemized")
          .map((item) => item.id)
      ),
    ];

    const priceIds = [
      ...new Set(
        checkedItems
          .filter((item) => item.type === "price")
          .map((item) => item.id)
      ),
    ];

    if (itemizedIds.length === 0 && priceIds.length === 0) {
      alertContext.showAlert("다운로드할 항목을 선택해주세요.");
      return;
    }

    try {
      loadingContexts.showLoading("엑셀 파일을 생성하는 중입니다.");

      const requestData = {
        itemized_cost_ids: itemizedIds.map((id) => parseInt(id)),
        price_calculation_ids: priceIds.map((id) => parseInt(id)),
      };

      const response = await aiApi.getStateExcel(requestData);

      if (response.excel_file_url?.length > 0) {
        let url = response.excel_file_url.replace(/\+/g, "%2B");

        const link = document.createElement("a");
        link.href = url;
        link.download = `일위대가_${new Date().getTime()}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        alertContext.showAlert("파일 다운로드가 완료되었습니다.");
      } else {
        alertContext.showAlert("다운로드할 파일이 없습니다.");
      }
    } catch (error) {
      console.error("Excel download error:", error);
      alertContext.showAlert("파일 다운로드 중 오류가 발생했습니다.");
    } finally {
      loadingContexts.closeLoading();
    }
  };

  return (
    <div className="search-result" ref={searchResultRef}>
      <SearchResultAside />
      <div className="search-result-content">
        {userStatement.map((message, index) =>
          message.role === "user" ? (
            <div key={index} className="search-result-content-body">
              <div className="search-result-content-body-item-list">
                <div className="search-result-content-body-title">
                  <span className="search-item-title">{message.content}</span>
                </div>
                <div className="search-result-content-body-item">
                  <div className="search-item">
                    {assistantStatement && assistantStatement[index] ? (
                      <div className="search-item-content">
                        <div className="search-item-content-markdown">
                          <span className="ant-design-icon">
                            <SearchOutlined />
                          </span>
                          <MarkdownTyper
                            isNew={assistantStatement[index].isNew || false}
                            markdown={assistantStatement[index].content || ""}
                            type="statement"
                            onComplete={(el: HTMLDivElement) => {
                              complete(el);
                            }}
                          />
                        </div>
                        {projects[index] &&
                          projects[index].item_name &&
                          !hideProjectList[index] && (
                            <div className="search-item-content-statement">
                              <ProjectTyper
                                projectList={projects[index]}
                                onProjectSelected={(project: any) =>
                                  handleProjectSelected(project, index)
                                }
                              />
                            </div>
                          )}
                        {projectPreview[index] &&
                          projectPreview[index].statements && (
                            <div className="search-item-content-statement">
                              <TableTyper
                                data={projectPreview[index]}
                                isPreview={isPreview[index] || false}
                              />
                            </div>
                          )}

                        {itemizedData[index] && (
                          <div className="search-item-content-statement">
                            <ItemizedTyper
                              statementId={assistantStatement[
                                index
                              ].id.toString()}
                              citations={citations[index]}
                              descriptions={descriptions[index]}
                              data={itemizedData[index]}
                            />
                          </div>
                        )}
                        {assistantStatement && assistantStatement[index] && (
                          <div className="feedback-container">
                            <span className="feedback-text">
                              이 대화가 만족스러우셨나요?
                            </span>
                            <div className="feedback-buttons">
                              <button
                                onClick={() => handleFeedback("좋아요", index)}
                                className={`feedback-button ${
                                  messageFeedbacks.some(
                                    (f) =>
                                      f.message_index === index &&
                                      f.feedback_type === "좋아요" &&
                                      f ===
                                        messageFeedbacks.find(
                                          (fb) => fb.message_index === index
                                        )
                                  )
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <LikeOutlined />
                              </button>
                              <button
                                onClick={() =>
                                  handleFeedback("별로에요", index)
                                }
                                className={`feedback-button ${
                                  messageFeedbacks.some(
                                    (f) =>
                                      f.message_index === index &&
                                      f.feedback_type === "별로에요" &&
                                      f ===
                                        messageFeedbacks.find(
                                          (fb) => fb.message_index === index
                                        )
                                  )
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <DislikeOutlined />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <span className="ant-design-icon">
                        <LoadingOutlined />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="search-result-content-body-citations">
                <StatementCitations
                  labels={labels[index] || []}
                  citations={searchResult[index]}
                  onItemizedSelected={(data: any, clickedIndex?: number) => {
                    handleItemizedSelected(data, index);
                  }}
                  onPreviewData={(data: any) => {
                    setProjects((prev: any) => {
                      const newData = Array(userStatement.length).fill(null);
                      return newData.map((item, idx) =>
                        idx === index ? data : prev[idx]
                      );
                    });
                    setIsPreview((prev) => ({
                      ...prev,
                      [index]: false,
                    }));
                    setItemizedData((prev: any) => {
                      const newData = [...prev];
                      newData[index] = null;
                      return newData;
                    });
                    setHideProjectList((prev) => ({
                      ...prev,
                      [index]: false,
                    }));
                  }}
                  onCheckItemizedCost={handleCheckItemizedCost}
                  onCheckPriceCalculation={handleCheckPriceCalculation}
                  resetCheckedItems={resetCheckedItems}
                  onResetComplete={handleResetComplete}
                />
              </div>
            </div>
          ) : null
        )}
        <div className="search-result-content-footer">
          <AiSearchInput
            loading={loading}
            onSearchSubmit={onSearchSubmit}
            placeholder="다른 궁금한 내용을 질문해보세요."
            tooltipText="현재 무료로 PRO 기능을 이용중입니다<br>최적화된 답변을 받을 수 있습니다."
            quota={quota}
            searchCategory={searchCategory}
          />
          <div className="citation-download-button-wrapper">
            <button className="reset-button" onClick={handleReset}>
              초기화
            </button>
            <button
              onClick={handleExcelDownload}
              disabled={checkedItems.length === 0}
            >
              {checkedItems.length}건 엑셀 다운로드
            </button>
          </div>
        </div>
      </div>

      {/* 피드백 모달 */}
      <Modal
        title="피드백을 남겨주세요"
        open={feedbackModalVisible}
        onCancel={() => setFeedbackModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleFeedbackSubmit}>
          <Form.Item
            name="feedback"
            rules={[{ required: true, message: "피드백 내용을 입력해주세요" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="답변에 대한 의견을 자유롭게 작성해주세요."
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              제출하기
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
