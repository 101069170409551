/* eslint-disable import/no-anonymous-default-export */
import { useGA4React } from "ga-4-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CouponBox from "../../components/coupon/CouponBox";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import DupLoginContext from "../../contexts/DupLoginContext";
import CouponPaymentModel from "../../models/coupon/CouponPaymentModel";
import { Imp } from "../../models/payment/iamport/IMP";
import {
  IamportPaymentRequestModel,
  IamportPayMethod,
  ImpCallbackResponseModel,
} from "../../models/payment/iamport/ImpModels";
import PaymentProductModel from "../../models/payment/PaymentProductModel";
import { UserInfoModel } from "../../models/user/UserInfo";
import GlobalEnvironments from "../../modules/globalEnvironment/GlobalEnvironment";
import PaymentPresenter from "./PaymentPresenter";
import queryString from "query-string";
import _ from "lodash";
import PaymentConfirmContent from "../../components/payment/PaymentConfirmContent/PaymentConfirmContent";
import CouponModal from "./components/CouponModal";
import ProductDetailPeriod from "./components/ProductDetailPeriod";
import PackageDetailPeriod from "./components/PackageDetailPeriod";
import PaymentReadyContainer from "./PaymentReadyContainer";
import {
  PaymentModelV2,
  PaymentPackageDetailModelV2,
  PaymentPackageModelV2,
  PaymentProductDetailModelV2,
  PaymentProductModelV2,
  PaymentSelectedProductModelV2,
} from "../../models/payment/PaymentProductModelV2";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";
import { CategoryItem } from "../../models/CategoryItem";

interface Props {}

export default (props: Props) => {
  const INIT_PRODUCT_PERIOD = 30;
  const history = useHistory();
  const alertContext = useContext(AlertContext);
  const [selectedGroupedProductIds, setSelectedGroupedProductIds] = useState<
    number[]
  >([]);
  const [selectedPayMethod, setSelectedPayMethod] =
    useState<IamportPayMethod>();
  /** 2: 내역서, 3: 타내역서, 1: 견적서 **/
  const permissionOrder = [2, 3, 1];
  const [userInfo, setUserInfo] = useState<UserInfoModel>();
  const [vbankInfo, setVbankInfo] = useState<any>();
  const [userCoupons, setUserCoupons] = useState<CouponPaymentModel[]>();
  const [selectedCoupon, setSelectedCoupon] = useState<CouponPaymentModel>();
  const [couponData, setCouponData] = useState<any[]>();
  const dupLogin = useContext(DupLoginContext);
  const ga4 = useGA4React();
  const location = useLocation();
  const [groupedProducts, setGroupedProducts] = useState<{
    [key: string]: PaymentProductModel[];
  }>();
  const [originGroupedProducts, setoriginGroupedProducts] = useState<{
    [key: string]: PaymentProductModel[];
  }>();
  const [selectedPeriod, setSelectedPeriod] = useState(INIT_PRODUCT_PERIOD);
  const [totalPrice, setTotalPrice] = useState(0);
  const [beforeAmount, setBeforeAmount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [permissionDescriptions, setPermissionDescriptions] = useState({});

  const [selectedData, setSelectedData] = useState<PaymentModelV2>();
  const [paymentData, setPaymentData] = useState<PaymentModelV2[]>();
  const [paymentCategories, setPaymentCategories] = useState<CategoryItem[]>();
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [isPaymentReady, setIsPaymentReady] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("product");
  const [selectedProduct, setSelectedProduct] = useState<
    PaymentSelectedProductModelV2[]
  >([]);
  const [selectedPackages, setSelectedPackages] = useState<
    PaymentSelectedProductModelV2[]
  >([]);

  useEffect(() => {
    if (location.search) {
      const parsed: any = queryString.parse(location.search);
      if (parsed?.imp_success != "false") {
        const product_ids: number[] =
          parsed?.product_ids != ""
            ? parsed?.product_ids.split(", ").map((id: string) => +id)
            : [];
        const package_detail_ids: number[] =
          parsed?.package_detail_ids != ""
            ? parsed?.package_detail_ids.split(", ").map((id: string) => +id)
            : [];
        parsed.custom_data = {
          product_ids,
          package_detail_ids,
          coupon_key: parsed?.coupon,
        };
        handleRequestPaymentCallback(parsed);
      } else {
        alertContext.showAlert("결제가 취소되었습니다.");
      }
    }
    new Promise(async () => {
      const res = await api.getPaymentProductsAsync();
      setPaymentData(res.data?.categories);
      setPaymentCategories(
        res.data?.categories.map((category: any, index: number) => {
          return {
            title: category.title,
            description: '',
            id: category.id
          };
        })
      );
      setSelectedData(res.data?.categories?.find(Boolean));
      const key = util.getCookie(Cookies.authKey);
      if (key) {
        const info = await dupLogin.checkDupLogin(() => api.getMyInfo());
        setUserInfo(info?.data);
        if (!info) {
          return;
        }
      } else {
        return;
      }

      await getCoupons();
    });
  }, []);

  const handleGroupedProductClickV2 = (
    period: number,
    detailId: number,
    amount: number,
    productId: number
  ) => {
    const res = selectedProduct?.findIndex((i) => i.productId === productId);
    // index가 0이어도 if문에 false처리되므로, 직접 나눠서 분기처리
    if (res == undefined || res === -1) {
      setSelectedProduct([
        ...selectedProduct,
        { id: detailId, period: period, amount: amount, productId },
      ]);
    } else {
      const selectedProducts = _.cloneDeep(selectedProduct);
      selectedProducts.splice(res, 1);
      setSelectedProduct(selectedProducts);
      setSelectedProduct([
        ...selectedProducts,
        { id: detailId, period: period, amount: amount, productId },
      ]);
    }
  };

  const handleGroupedPackageClickV2 = (
    period: number,
    detailId: number,
    amount: number,
    productId: number
  ) => {
    const res = selectedPackages?.findIndex((i) => i.productId === productId);
    // index가 0이어도 if문에 false처리되므로, 직접 나눠서 분기처리
    if (res == undefined || res === -1) {
      setSelectedPackages([
        ...selectedPackages,
        { id: detailId, period: period, amount: amount, productId },
      ]);
    } else {
      const selectedPackagess = _.cloneDeep(selectedPackages);
      selectedPackagess.splice(res, 1);
      setSelectedPackages(selectedPackagess);
      setSelectedPackages([
        ...selectedPackagess,
        { id: detailId, period: period, amount: amount, productId },
      ]);
    }
  };

  const deleteProduct = (
    period: number,
    detailId: number,
    amount: number,
    productId: number
  ) => {
    const res = selectedProduct?.findIndex(
      (i) =>
        i.productId === productId && i.period === period && i.amount === amount
    );
    // index가 0이어도 if문에 false처리되므로, 직접 나눠서 분기처리
    if (res == undefined || res === -1) {
    } else {
      const selectedProducts = _.cloneDeep(selectedProduct);
      selectedProducts.splice(res, 1);
      setSelectedProduct(selectedProducts);
    }
  };

  const deletePackage = (
    period: number,
    detailId: number,
    amount: number,
    productId: number
  ) => {
    const res = selectedPackages?.findIndex(
      (i) =>
        i.productId === productId && i.period === period && i.amount === amount
    );
    // index가 0이어도 if문에 false처리되므로, 직접 나눠서 분기처리
    if (res == undefined || res === -1) {
    } else {
      const tempPackages = _.cloneDeep(selectedPackages);
      tempPackages.splice(res, 1);
      setSelectedPackages(tempPackages);
    }
  };

  const handleClickPayMethod = (method: IamportPayMethod) => {
    setSelectedPayMethod(method);
  };

  const getCoupons = async () => {
    const couponsRes = await dupLogin.checkDupLogin(() =>
      api.getCouponHistoryAsync().then((couponsRes) => {
        if (couponsRes instanceof Object) {
          setCouponData(
            couponsRes.data.coupons.map((coupon: any) => {
              return {
                key: coupon.key,
                amount: coupon.benefit_amount,
                benefitType: coupon.benefit_type,
              };
            })
          );
          const userCoupons: CouponPaymentModel[] = couponsRes.data.coupons.map(
            (coupon: any): CouponPaymentModel => {
              return {
                expiryDate: new Date(coupon.expiry_date).format("yyyy.MM.dd"),
                name: coupon.name,
                key: coupon.key,
              };
            }
          );
          setUserCoupons(userCoupons);
          return userCoupons;
        }
      })
    );
    const result = (couponsRes instanceof Object && couponsRes) || undefined;
    return result;
  };

  const handleChangeSelectedCoupon = (key: string) => {
    const selectedCouponModel = userCoupons?.find(
      (coupon) => coupon.key === key
    );
    setSelectedCoupon(selectedCouponModel);
    const selectedCouponData = couponData?.find(
      (coupon) => coupon.key === selectedCouponModel?.key
    );
    const originProducts = _.cloneDeep(originGroupedProducts);
    if (originProducts) {
      if (selectedCouponData) {
        Object.keys(originProducts).map((key) => {
          const currentProducts = originProducts?.[key];

          const discountedProducts = currentProducts?.map((product, index) => {
            let price: number = product.discountedPrice ?? 0;
            let double: number = product.doubleDiscountPrice ?? 0;
            let triple: number = product.tripleDiscountPrice ?? 0;
            if (selectedCouponData) {
              const type = selectedCouponData.benefitType;
              const originAmount = product.discountedPrice ?? 0;
              const originDouble = product.doubleDiscountPrice ?? 0;
              const originTriple = product.tripleDiscountPrice ?? 0;
              price =
                type === "price"
                  ? originAmount - +selectedCouponData.amount
                  : originAmount -
                    originAmount * selectedCouponData.amount * 0.01;
              double =
                type === "price"
                  ? originDouble
                  : originDouble -
                    originDouble * selectedCouponData.amount * 0.01;
              triple =
                type === "price"
                  ? originTriple
                  : originTriple -
                    originTriple * selectedCouponData.amount * 0.01;
            }
            return {
              ...product,
              discountedPrice: price,
              doubleDiscountPrice: double,
              tripleDiscountPrice: triple,
            };
          });

          originProducts[key] = discountedProducts;
        });
      }
      setGroupedProducts(originProducts);
    }
  };

  const handlePaymentClick = async () => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alertContext.showAlert("로그인 후 이용해주세요.");
      history.push(pages.login);
      return;
    } else {
      const res = await dupLogin.checkDupLogin(() => api.getMyInfo());
      if (!res || res?.status === 401) {
        return;
      }
    }
    startPayment();
  };

  const calcTotalPrice = (): number => {
    let localTotalPrice = 0;
    let beforePrice = 0;

    selectedProduct.forEach((model) => {
      localTotalPrice += model.amount;
    });
    selectedPackages.forEach((model) => {
      localTotalPrice += model.amount;
    });
    setTotalPrice(localTotalPrice);
    // const percent = Math.min(
    //   Math.round((1 - localTotalPrice / beforePrice) * 100),
    //   beforePrice
    // );

    // setDiscountPercent(percent);
    return localTotalPrice;
  };

  const getSelectedProducts = () => {
    return selectedGroupedProductIds.map((id) =>
      groupedProducts?.[selectedPeriod].find((prod) => prod.id === id)
    );
  };

  const getProduct = (productId: number): PaymentProductModelV2 => {
    const products = selectedData?.products;
    const productById = products?.filter(
      (product) => product.id === productId
    )[0];
    return productById!;
  };

  const getPackage = (productId: number): PaymentPackageModelV2 => {
    const products = selectedData?.packages;
    const productById = products?.filter(
      (product) => product.id === productId
    )[0];
    return productById!;
  };

  const startPayment = async () => {
    if (selectedProduct?.length === 0 && selectedPackages?.length === 0) {
      alertContext.showAlert("상품을 선택해주세요.");
      return;
    }

    if (selectedPayMethod === undefined) {
      alertContext.showAlert("결제방식을 선택해주세요.");
      return;
    }

    //설계 자동화만 나오게하기
    if (selectedData?.id === 1) {
      await alertContext.showContentModal(
        <PaymentConfirmContent close={alertContext.closeAlert} />,
        {
          width: "650px",
          height: "650px",
        }
      );
    }

    let totalPrice = 0;
    totalPrice = calcTotalPrice();
    try {
      var date = new Date();
      date.setDate(date.getDate() + 3);
      const noticeUrls = [`${process.env.REACT_APP_API_URL}payment/approval/`];
      if (GlobalEnvironments.getIsSendLocalNotice()) {
        noticeUrls.push(
          "flamingo-pleasant-dassie.ngrok-free.app/payment/approval/"
        );
      }
      const currentProducts = getSelectedProducts();
      const prodNames = currentProducts?.map((prod) =>
        prod?.name.replaceAll(`\n`, " ")
      );
      const query = queryString.stringify({
        product_ids: selectedProduct.map((product) => product.id).join(", "),
        package_detail_ids: selectedPackages
          .map((product) => product.id)
          .join(", "),
        coupon: selectedCoupon?.key,
        names: prodNames,
        buyer_tel: userInfo?.phone || "",
        buyer_name: userInfo?.last_name || "",
        buyer_email: userInfo?.email || "",
        pay_method: selectedPayMethod,
      });
      const firstProduct =
        selectedType === "product"
          ? getProduct(selectedProduct[0].productId)
          : getPackage(selectedPackages[0].productId);
      const requestData: IamportPaymentRequestModel = {
        pg: "html5_inicis",
        amount: totalPrice,
        buyer_tel: userInfo?.phone || "",
        pay_method: selectedPayMethod || IamportPayMethod.default,
        // 추후 필요하면 차제 mid생성 로직 구현
        merchant_uid: `mid_${new Date().getTime()}`,
        // name에는 개행문자가 포함되어있으면 결제창이 제대로 뜨지 않음.
        name: firstProduct.name,
        buyer_name: userInfo?.last_name || "",
        buyer_addr: userInfo?.addr || "",
        buyer_email: userInfo?.email || "",
        buyer_postcode: userInfo?.post_code || "",
        custom_data: {
          product_id: firstProduct?.id,
          product_ids: selectedProduct.map((product) => product.id),
          package_detail_ids: selectedPackages.map((product) => product.id),
          coupon_key: selectedCoupon?.key,
        },
        vbank_due: date.format("yyyyyMMdd"),
        notice_url: noticeUrls,
        m_redirect_url: `${GlobalEnvironments.getAppUrl()}payment?${query}`,
      };
      Imp.requestPay(requestData, handleRequestPaymentCallback);
    } catch (error) {}
  };

  const handleRequestPaymentCallback = async (
    res: ImpCallbackResponseModel
  ) => {
    try {
      if (res?.success === false || res?.imp_success === false) {
        if (res.error_msg?.includes("취소")) {
          alertContext.showAlert("사용자의 요청으로 취소되었습니다.");
          return;
        }
        if (res.error_msg == undefined) {
          alertContext.showAlert(
            "결제가 정상적으로 완료되지 않았습니다.\n우측하단 고객센터를 통해 문의 바랍니다."
          );
        } else {
          alertContext.showAlert(res.error_msg);
        }
        return;
      }
      const completeRes = await dupLogin.checkDupLogin(() =>
        api.postPaymentCompleteAsync(res)
      );
      if (completeRes?.status === 200) {
        if ((selectedPayMethod || res.pay_method) === IamportPayMethod.card) {
          try {
            if (ga4 instanceof Object) {
              ga4.gtag("event", "purchase", {
                currency: "KRW",
                value: res.paid_amount,
                coupon: selectedCoupon?.key,
                items: getSelectedProducts(),
              });
            }
          } catch (err) {}

          alertContext.showAlert("결제가 완료되었습니다.", () => {
            history.push(pages.paymentComplete);
          });
        } else {
          setVbankInfo({
            amount: res.paid_amount,
            prodName: res.name,
            vbankDate: new Date(completeRes?.data.vbank_date * 1000).format(
              "yyyy년 MM월 dd일"
            ),
            vbankName: completeRes?.data.vbank_name,
            vbankNum: completeRes?.data.vbank_num,
            name: res.buyer_name,
            email: res.buyer_email,
            phone: res.buyer_tel,
            companyName: res.vbank_holder,
          });
          alertContext.showAlert(
            "가상계좌 정보는 마이페이지 결제이력의\n'계좌 확인'을 통해 확인해주세요.",
            () => {
              history.push(pages.mypage + "/payment");
            }
          );
        }
      } else if ([400, 500].includes(completeRes?.status)) {
        // 결제 실패. custom_data가 안가서 결제검증을 진행할 수 없음.
        history.push(pages.payment);
        alertContext.showAlert(
          "결제 중 오류가 발생하였습니다.\n" + (completeRes?.message ?? "")
        );
      }
    } catch (error) {
      history.push(pages.payment);
      alertContext.showAlert(
        "상품등록 중 오류가 발생하였습니다.\n고객센터를 통해 문의 부탁드립니다."
      );
    }
  };

  const handleClickCoupon = async (userCoupons?: CouponPaymentModel[]) => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alertContext.showAlert("로그인 후 이용해주세요.");
      return;
    }
    alertContext.showContentModal(
      <CouponBox
        coupons={userCoupons}
        onConfirm={(key) => {
          handleChangeSelectedCoupon(key || "");
          alertContext.closeAlert();
        }}
        isSelectable={true}
        selectedCoupon={selectedCoupon}
        refreshCoupons={async () => {
          // warning: 재귀 주의.
          handleClickCoupon(await getCoupons());
        }}
      />,
      {
        width: "650px",
        height: "600px",
      }
    );
  };

  useEffect(() => {
    calcTotalPrice();
  }, [selectedProduct, selectedPackages]);

  const handlePeriodClick = (period: number) => {
    setSelectedPeriod(period);
    groupedProducts && setSelectedGroupedProductIds([]);
  };

  const paymentReady = () => {
    if (selectedProduct.length > 0 || selectedPackages.length > 0) {
      setIsPaymentReady(true);
    } else {
      alertContext.showAlert("결제할 상품을 선택해주세요.");
    }
  };

  return isPaymentReady ? (
    <>
      <PaymentReadyContainer
        selectedProduct={selectedProduct}
        selectedPackages={selectedPackages}
        totalPrice={totalPrice}
        deleteProduct={deleteProduct}
        deletePackage={deletePackage}
        selectedData={selectedData}
        handlePaymentClick={handlePaymentClick}
        handleClickPayMethod={handleClickPayMethod}
        coupons={userCoupons}
        selectedCoupon={selectedCoupon}
        selectedType={selectedType}
      />
      <CouponModal
        coupons={userCoupons}
        selectedCoupon={selectedCoupon}
        onConfirm={(key) => {
          handleChangeSelectedCoupon(key || "");
          // alertContext.closeAlert();
        }}
        isSelectable={true}
        refreshCoupons={async () => {
          // warning: 재귀 주의.
          // handleClickCoupon(await getCoupons());
        }}
      />
    </>
  ) : (
    <main id="wrap">
      <article>
        <section>
          <div className="container content">
            <h5 className="marketing-step">STEP 01</h5>
            <h3
              className="marketing-title pb-3 aos-init aos-animate mb-7"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              고객님의 비즈니스에 <span>적합한 서비스</span>를 선택하세요.
            </h3>
            <CategorySelector
              categories={paymentCategories}
              selectedCategoryIndex={selectedCategoryIndex}
              onClickCategory={(index) => {
                setSelectedCategoryIndex(index);
                setSelectedData(paymentData?.[index]);
                setSelectedProduct([]);
                setSelectedPackages([]);
                const type = index === 2 ? "package" : "product";
                setSelectedType(type);
              }}
            />
            <div
              className="tab-content pt-6"
              id="paymentContent"
              style={{ userSelect: "auto" }}
            >
              <div
                className="tab-pane fade show active"
                id="payment1-tab-pane"
                role="tabpanel"
                aria-labelledby="payment1-tab"
                tabIndex={0}
                style={{ userSelect: "auto" }}
              >
                <div className="payment-wrap">
                  {selectedData?.packages?.map(
                    (_package: PaymentPackageModelV2, index: number) => {
                      return (
                        <PackageDetailPeriod
                          package={_package}
                          index={index}
                          handleGroupedProductClick={
                            handleGroupedPackageClickV2
                          }
                          deleteProduct={deletePackage}
                          key={_package.id + index}
                        />
                      );
                    }
                  )}

                  {selectedData?.products?.length ?? 0 > 0 ? (
                    <div className="payment-grid">
                      {selectedData?.products!.map(
                        (product: PaymentProductModelV2, index: number) => {
                          return (
                            <ProductDetailPeriod
                              product={product}
                              index={index}
                              handleGroupedProductClick={
                                handleGroupedProductClickV2
                              }
                              deleteProduct={deleteProduct}
                              key={product.id + index}
                            />
                          );
                        }
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-primary">
          <div className="container content">
            <div className="marketing-page">
              <h3
                className="marketing-title white pb-3 aos-init"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                작성 시간 단축을 <span>100%</span> 보장합니다.
              </h3>
              <h4 className="marketing-title-sub white">
                콘엑스를 사용하면, 하루를 소요했던 작업이 한 시간 만에
                완료됩니다.
              </h4>
              <div className="col-12 col-md-9 mx-auto">
                <img
                  src="/images/ddusul/CK_ti324a263092.png"
                  className="w-100 aos-init"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        </section>
        <div className="payment-bottom" style={{ zIndex: 5 }}>
          <div className="container">
            <div className="payment-bottom-wrap">
              <div className="pay-price">
                <div>
                  <span className="material-symbols-outlined">
                    shopping_bag
                  </span>
                </div>
                <div className="d-flex align-items-center gap-3">
                  {/* <div className="price-sale">
                    <span>0</span>원
                  </div> */}
                  <div className="price">
                    <span>{util.addComma(totalPrice)}</span>원
                  </div>
                </div>
              </div>
              <div className="payment-bottom-btn">
                <div className="bottom-btn-column">
                  {/* <button
                    type="button"
                    className="btn btn-white btn-lg left-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#CouponModal"
                  >
                    <span className="material-symbols-outlined me-2">
                      confirmation_number
                    </span>
                    쿠폰 사용
                  </button> */}
                  {/* <a
                    href="cart.html"
                    className="btn btn-white btn-lg left-icon"
                  >
                    <span className="material-symbols-outlined me-2">
                      shopping_bag
                    </span>
                    장바구니
                  </a> */}
                </div>
                <a
                  onClick={() => {
                    paymentReady();
                  }}
                  className="btn btn-primary btn-lg left-icon"
                >
                  <span className="material-symbols-outlined me-2">paid</span>
                  결제하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
      <CouponModal
        coupons={userCoupons}
        selectedCoupon={selectedCoupon}
        onConfirm={(key) => {
          handleChangeSelectedCoupon(key || "");
          // alertContext.closeAlert();
        }}
        isSelectable={true}
        refreshCoupons={async () => {
          // warning: 재귀 주의.
          // handleClickCoupon(await getCoupons());
        }}
      />
    </main>
  );
};
