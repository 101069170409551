import React, { useContext, useEffect, useState, useRef } from "react";
import "./ItemizedTyper.scss";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { aiApi } from "../../config/aiApi";
import Typed from "typed.js";

import { AlertContext } from "../../contexts/AlertContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import axios from "axios";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

interface ItemizedTyperProps {
  data: { id: any; type: string; name: string; label: string };
  statementId: string;
  citations: any[];
  descriptions: any[];
  onHideProjectList?: (hide: boolean) => void;
}

const getPreviewUrl = (data: { id: string; type: string }) => {
  const baseUrl = process.env.REACT_APP_COST_URL;
  return data.type === "price"
    ? `${baseUrl}/statement-ai/price?seq=${data.id}`
    : `${baseUrl}/statement-ai?seq=${data.id}`;
};

const ItemizedTyper: React.FC<ItemizedTyperProps> = ({
  statementId,
  data,
  citations,
  descriptions,
  onHideProjectList,
}) => {
  const location = useLocation();
  const alertContext = useContext(AlertContext);
  const loadingContext = useContext(LoadingContext);
  const [frameHeight, setFrameHeight] = useState<string>("");
  const [itemizedData, setItemizedData] = useState<any[]>([data]);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeId = useRef(`iframe-${data.id}-${Date.now()}`).current;
  const [description, setDescription] = useState<string>("");
  const [stateCitations, setStateCitations] = useState<any>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const typedRef = useRef<HTMLSpanElement>(null);
  const typed = useRef<Typed | null>(null);

  useEffect(() => {
    if (onHideProjectList && data.type === "price") {
      onHideProjectList(true);
    }

    return () => {
      if (onHideProjectList) {
        onHideProjectList(false);
      }
    };
  }, [data.type, onHideProjectList]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.data &&
        event.data.type === "resize" &&
        event.data.iframeId === iframeId
      ) {
        setFrameHeight(`${event.data.height}px`);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeId]);

  useEffect(() => {
    if (description && typedRef.current) {
      if (typed.current) {
        typed.current.destroy();
      }

      typedRef.current.textContent = "";

      typed.current = new Typed(typedRef.current, {
        strings: [description],
        typeSpeed: 20,
        showCursor: false,
        loop: false,
      });
    }

    return () => {
      if (typed.current) {
        typed.current.destroy();
      }
    };
  }, [description]);

  const handlePdfDownload = async () => {
    try {
      loadingContext.showLoading();
      const response = await aiApi.getitemizedToPdf(data.id);
      let url = response.pdf_url;

      if (!url) {
        throw new Error("PDF URL이 유효하지 않습니다.");
      }

      url = url.replace(/\+/g, "%2B");
      // 새 탭에서 PDF 열기
      window.open(url, "_blank");
    } catch (error) {
      console.error("PDF download error:", error);
      alertContext.showAlert("해당하는 PDF 파일이 없습니다.");
    } finally {
      loadingContext.closeLoading();
    }
  };
  const handleExcelDownload = async () => {
    try {
      const requestData = {
        itemized_cost_ids: data.type === "price" ? [] : [Number(data.id)],
        price_calculation_ids: data.type === "price" ? [Number(data.id)] : [],
      };
      const response = await aiApi.getStateExcel(requestData);

      if (response.excel_file_url?.length > 0) {
        let url = response.excel_file_url.replace(/\+/g, "%2B");

        const link = document.createElement("a");
        link.href = url;
        link.download = `일위대가_${new Date().getTime()}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        alertContext.showAlert("파일 다운로드가 완료되었습니다.");
      } else {
        alertContext.showAlert("다운로드할 파일이 없습니다.");
      }
    } catch (error) {
      console.error("Excel download error:", error);
      alertContext.showAlert("해당하는 엑셀 파일이 없습니다.");
    } finally {
      loadingContext.closeLoading();
    }
  };

  const handleItemizedSelected = (data: any, index?: number) => {
    if (index !== undefined) {
      setItemizedData((prevData: any) => {
        const newData = [...prevData];
        newData[index] = data;
        return newData;
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      console.log("descriptions", descriptions);

      try {
        // 설명 가져오기
        const descriptionResponse = await aiApi.getStatementDescription(
          statementId,
          data.label
        );
        if (descriptionResponse.descriptions[0].description) {
          setDescription(descriptionResponse.descriptions[0].description);
        } else {
          setDescription("설명이 없습니다.");
        }

        // 인용 가져오기
        const citationsResponse = await aiApi.getStatementCitations(
          statementId,
          data.label
        );
        setFlag(true);
        setStateCitations(citationsResponse.citations);
      } catch (error) {
        console.error("데이터 로딩 오류:", error);
        setDescription("설명을 불러오는 중 오류가 발생했습니다.");
        setStateCitations([]);
      }
    };

    if (statementId && data.label) {
      fetchData();
    }
  }, [statementId, data.label, descriptions, citations, location.search]);

  return (
    <div className="ai-statement">
      <div className="ai-statement-description">
        <div className="ai-statement-description-title">
          <b>{data.name}</b> AI 설명 - {data.label}
        </div>
        <div className="ai-statement-description-content">
          <span ref={typedRef}></span>
        </div>
      </div>
      <div className="ai-statement-header">
        {data.type === "statement" ? (
          <span className="ai-statement-title">
            <b>{data.name}</b>의 일위대가 미리보기
          </span>
        ) : (
          <span className="ai-statement-title">
            <b>{data.name}</b>의 단가산출 미리보기
          </span>
        )}
        <div className="ai-statement-header-button">
          {flag && (
            <>
              <Tooltip title="엑셀 다운로드">
                <FileExcelOutlined
                  onClick={handleExcelDownload}
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
              {data.type === "statement" && (
                <Tooltip title="PDF 보기">
                  <FilePdfOutlined
                    onClick={handlePdfDownload}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      <div className="ai-statement-content">
        <iframe
          ref={iframeRef}
          title="AIStatement"
          src={`${getPreviewUrl(data)}${
            getPreviewUrl(data).includes("?") ? "&" : "?"
          }iframeId=${iframeId}`}
          style={{
            width: "100%",
            height: frameHeight,
          }}
        />
      </div>
      <div className="ai-statement-citations">
        <div className="ai-statement-citations-title">참고자료</div>
        <div className="citations-card-container">
          {stateCitations?.length > 0 ? (
            stateCitations.map((citation: any, index: number) => (
              <div className="citation-card" key={index}>
                <a
                  href={citation.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="citation-title">
                    {citation.title === null
                      ? `참고자료[${index + 1}]`
                      : citation.title}
                  </div>
                  <div className="citation-url">{citation.url}</div>
                </a>
              </div>
            ))
          ) : (
            <div>참고자료가 없습니다.</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ItemizedTyper;
