import React, { useEffect, useState } from "react";
import "./ProjectTyper.scss";

interface Project {
  item_name: string;
  projects: any;
}
interface ProjectTyperProps {
  projectList: Project | { item_name: string; message: string };
  onProjectSelected: (project: Project) => void;
}

const ProjectTyper: React.FC<ProjectTyperProps> = ({
  projectList,
  onProjectSelected,
}) => {
  return (
    projectList && (
      <div className="project-typer-wrapper">
        <div className="project-typer-title">
          <span>
            <b style={{ color: "var(--color-primary)" }}>
              {projectList.item_name} 프로젝트 목록
            </b>
          </span>
        </div>
        {"message" in projectList ? (
          <div className="project-typer-list">
            <div className="project-typer-list-message">
              <span>{projectList.message}</span>
            </div>
          </div>
        ) : (
          <div className="project-typer-list">
            {"projects" in projectList &&
              projectList.projects.map((project: any, projectIndex: number) => (
                <div
                  className="project-typer-list-item"
                  key={`${project.id}-${projectIndex}`}
                  onClick={() => onProjectSelected(project)}
                >
                  <span className="project-title">
                    {projectIndex + 1}. {project.name}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    )
  );
};

export default ProjectTyper;
