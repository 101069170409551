/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useContext, useEffect, useState } from "react";

import { pages } from "../../constants/PagePaths";
import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { EstimateService } from "../../models/EstimateService";

interface Props {}

export default (props: Props) => {
  const [selectedService, setSelectedService] = useState(0);
  const history = useHistory();
  const [estimateData, setEstimateData] = useState<EstimateService[]>([]);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const noticeSize: number = 7;
  const [minIndex, setMinIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>(7);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);

  useEffect(() => {
    api
      .getEstimate()
      .then((result) => {
        const response: any = result.data;
        setEstimateData(response.estimate_services);
      })
      .catch((error) => {
        console.error("에러발생 : ", error);
      });
  }, []);

  const handleChange = (page: number) => {
    setCurrent(page);
    setMinIndex((page - 1) * noticeSize);
    setMaxIndex(page * noticeSize);
  };

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container content">
            <div className="marketing-page">
              <h3
                className="marketing-title pb-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                고객님께서 찾던 모든 서비스가 <span>콘엑스</span>에 있습니다.
              </h3>
              <h4 className="marketing-title-sub">
                필요한 건설업무 의뢰를 선택해보세요.
              </h4>
              <div className="marketing-list">
                <div
                  className="marketing-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  onClick={() => {
                    history.push("/requestapplication/integration");
                  }}
                >
                  <div className="title">
                    <img src="/images/ddusul/icon/icon-87.png" />
                    적산
                  </div>
                  <div className="body">
                    공사의 필요한 단가 및 수량 등을 계산하며, 토목, 조경, 건축
                    분야 용역을 수행합니다.
                  </div>
                </div>
                <div
                  className="marketing-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  onClick={() => {
                    history.push("/requestapplication/negotiated");
                  }}
                >
                  <div className="title">
                    <img src="/images/ddusul/icon/icon-86.png" />
                    설계내역
                  </div>
                  <div className="body">
                    관공서(지자체, 공공기관 등) 수의계약 설계내역작업 의뢰에
                    대한 용역을 수행합니다.
                  </div>
                </div>
                <div
                  className="marketing-item aos-init"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  onClick={() => {
                    history.push("/requestapplication/designchange");
                  }}
                >
                  <div className="title">
                    <img src="/images/ddusul/icon/icon-85.png" />
                    설계변경
                  </div>
                  <div className="body">
                    실정 보고서, 검토서, 설계변경 합의서 등 변경에 필요한 도면,
                    수량, 단가, 내역 작업 용역을 수행합니다.
                  </div>
                </div>
                <div
                  className="marketing-item aos-init"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  onClick={() => {
                    history.push("/requestapplication/documentreview");
                  }}
                >
                  <div className="title">
                    <img src="/images/ddusul/icon/icon-84.png" />
                    서류검토
                  </div>
                  <div className="body">
                    계약, 설계, 변경 등 각종 공사 서류 및 공무 서류를
                    검토합니다.
                  </div>
                </div>
                <div
                  className="marketing-item aos-init"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  onClick={() => {
                    history.push("/requestapplication/etc");
                  }}
                >
                  <div className="title">
                    <img src="/images/ddusul/icon/icon-83.png" />
                    경리서류
                  </div>
                  <div className="body">
                    각종 계약 서류부터, 준공 서류까지 업무에 필요한 모든 서류를
                    제작하고 관리합니다.
                  </div>
                </div>
                <div
                  className="marketing-item aos-init"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  onClick={() => {
                    history.push("/requestapplication/estimate");
                  }}
                >
                  <div className="title">
                    <img
                      src="/images/ddusul/icon/icon-82.png"
                      style={{ objectFit: "contain" }}
                    />
                    공내역서
                  </div>
                  <div className="body">
                    건설공사에 필요한 내용을 기준으로 합리적인 견적을
                    산출합니다.
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center mt-10 mx-auto">
                <a
                  className="btn btn-xlg btn-primary w-100"
                  href={pages.requestapplication}
                >
                  빠른 용역 의뢰하기
                </a>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="full-img-wrap">
            <h3>콘엑스는 사업의 마무리까지 책임집니다.</h3>
          </div>
        </section>
        <section>
          <div className="container content">
            <div className="marketing-page">
              <div className="advantages">
                <h3
                  className="marketing-title aos-init"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  단순 의뢰 서비스만이 아닌{" "}
                  <span>용역, 검토 등 사업의 마무리까지</span>
                  <br />
                  모든 과정을 콘엑스가 함께합니다.
                </h3>
                <div className="advantages-item-wrap">
                  <div
                    className="item-group aos-init"
                    data-aos="flip-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                  >
                    <div className="item-img">
                      <img src="/images/ddusul/CK_cm270028213.jpg" />
                    </div>
                    <div className="item">
                      <div className="py-5 px-5">
                        <span>01</span>
                        <h3>의뢰 내용 검토</h3>
                        <div>
                          건설 분야 전문가들과 함께 요청받은 의뢰를 면밀히
                          검토합니다. 용역 제공 범위를 정하여 업무 프로세스를
                          수립합니다.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item-group reverse aos-init"
                    data-aos="flip-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                  >
                    <div className="item">
                      <div className="py-5 px-5">
                        <span>02</span>
                        <h3>계약 후 서비스 제공</h3>
                        <div>
                          기간, 예산 등 세부적인 진행 내용을 협의하여 계약을
                          진행합니다. 의뢰에 최적화된 용역 서비스를 수행합니다.
                          또한 지속적인 관리 계약을 진행하여 비용을 절감할 수
                          있도록 합니다.
                        </div>
                      </div>
                    </div>
                    <div className="item-img">
                      <img src="/images/ddusul/CK_pc002283824.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-50">
          <div className="container content">
            <div className="marketing-page">
              <div className="advantages">
                <h3
                  className="marketing-title pb-3 aos-init"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  써보면, <span>확실히 다릅니다.</span>
                </h3>
                <h4 className="marketing-title-sub">
                  한번도 안써본 사람은 있지만, 한번만 써본 사람은 없습니다.
                </h4>
                <div className="comment-item-wrap">
                  <div
                    className="item aos-init"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1000"
                  >
                    <div className="comment-wrap">
                      <div className="avatar">
                        <img src="/images/ddusul/user1.png" />
                      </div>
                      <h3 className="title">
                        콘엑스를 알게 된 후 워라벨이 생겼어요!
                      </h3>
                      <div className="comment">
                        설계내역을 작성하는 업무를 도맡아 하다 보니 정시퇴근을
                        하는 날보다 야근하는 날이 더 많았습니다. 설계를 알게 된
                        후, 회사 직원의 권유로 뚜껑 저는 더이상 야근을 하지
                        않습니다. 집에 빨리 들어가니 와이프도 좋아하고 아이들과
                        보내는 시간이 더 길어져 삶이 윤택해졌습니다. 콘엑스를
                        개발자 분들께 꼭 감사하다고 인사 전하고 싶습니다.
                      </div>
                    </div>
                  </div>
                  <div
                    className="item aos-init"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1000"
                  >
                    <div className="comment-wrap">
                      <div className="avatar">
                        <img src="/images/ddusul/user2.png" />
                      </div>
                      <h3 className="title">
                        더이상 엑셀이 무섭지 않게 되었습니다.
                      </h3>
                      <div className="comment">
                        엑셀로 내역작업과 설계변경 업무를 하면서, 하나 하나
                        수정하는 것이 매우 번거로웠습니다. 또한 엑셀에서 계산
                        실수라도 하는 날이면 눈앞이 깜깜해졌었는데,
                        콘엑스에서는 자동으로 모든 계산을 해주고 최종
                        엑셀파일로 변환해주니 너무 쉽고 편하네요. 다른 분들께도
                        강력 추천 드립니다!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};
