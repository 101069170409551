import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { videoApi } from "../../../config/videoApi";
import { pages } from "../../../constants/PagePaths";

export const lectureModels = [
  {
    id: 8,
    title: "계약,기성,준공 내역서강의",
    category: "건설실무",
    userCount: 230,
  },
  {
    id: 7,
    title: "조경설계 올인원",
    category: "조경",
    userCount: 160,
  },
  {
    id: 9,
    title: "토목 포장공사 강의",
    category: "토목",
    userCount: 190,
  },
  {
    id: 6,
    title: "조경 기본",
    category: "조경",
    userCount: 250,
  },
  {
    id: 3,
    title: "조경 식재",
    category: "조경",
    userCount: 250,
  },
  {
    id: 4,
    title: "조경 시설물",
    category: "조경",
    userCount: 170,
  },
  {
    id: 5,
    title: "조경 설계 변경",
    category: "조경",
    userCount: 140,
  },
  {
    id: 10,
    title: "돌담쌓기(겹담+외담)초급강의",
    category: "석공사",
    userCount: 30,
  },
];

export const lectureCategories = [
  "건설실무",
  "조경",
  "토목",
  "석공사",
  "건축(준비중)",
  "전기(준비중)",
];

export default () => {
  const history = useHistory();

  // 8 7 9 6 3 4 5

  const [lectures, setLectures] = useState<any>([]);

  useEffect(() => {
    new Promise(async () => {
      getCourse();
    });
  }, []);

  const getCourse = async (filter?: string) => {
    const res = await videoApi.getCourseListAsync();

    console.log(res);
    res.data.courses.sort(function (a: any, b: any) {
      return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
    });

    let searchModels = lectureModels;
    if (filter) {
      searchModels = lectureModels.filter(
        (lecture) => lecture?.category == filter
      );
    }
    const orderedCourses = searchModels.map((lectureModel) =>
      res.data.courses.find((course: any) => course.id === lectureModel.id)
    );
    setLectures(orderedCourses);
  };

  const lectureView = () => {
    if (lectures.length > 4) {
      return (
        <>
          <div
            className="card-wrap column3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {lectures.slice(0, 3).map((lecture: any) => {
              return (
                <div
                  className="card pointer"
                  onClick={() =>
                    history.push(`video/curriculum/${lecture?.id}`)
                  }
                >
                  <img
                    src={lecture?.card_image}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">{lecture?.title}</h5>
                    <div className="body2 mb-2">{lecture?.description}</div>
                    <div className="card-text mb-2">
                      <div className="card-caption">
                        {lecture.rating != null ? (
                          <div className="rating">
                            <span>{lecture.rating.toFixed(1)}</span>
                            <div className="star-wrap">
                              {Array.from({ length: lecture.rating }).map(
                                (_, index) => (
                                  <span></span>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="price">
                          <span className="discount">
                            <span>
                              {lecture?.origin_amount.toLocaleString()}
                            </span>
                            원
                          </span>
                          <span>{lecture?.amount.toLocaleString()}</span>원
                        </div>
                      </div>
                    </div>
                    <div className="badge-wrap">
                      <span className="badge rounded-pill text-bg-primary-soft">
                        {
                          lectureModels.filter(
                            (lectureModel) => lectureModel.id == lecture?.id
                          )[0]?.userCount
                        }
                        명
                      </span>
                      <span className="badge rounded-pill text-bg-primary">
                        {lecture?.service_product}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="card-wrap column4"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {lectures.slice(3).map((lecture: any) => {
              return (
                <div
                  className="card pointer"
                  onClick={() =>
                    history.push(`video/curriculum/${lecture?.id}`)
                  }
                >
                  <img
                    src={lecture?.card_image}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">{lecture?.title}</h5>
                    <div className="body2 mb-2">{lecture?.description}</div>
                    <div className="card-text mb-2">
                      <div className="card-caption">
                        {lecture.rating != null ? (
                          <div className="rating">
                            <span>{lecture.rating.toFixed(1)}</span>
                            <div className="star-wrap">
                              {Array.from({ length: lecture.rating }).map(
                                (_, index) => (
                                  <span></span>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="price">
                          <span className="discount">
                            <span>
                              {lecture?.origin_amount.toLocaleString()}
                            </span>
                            원
                          </span>
                          <span>{lecture?.amount.toLocaleString()}</span>원
                        </div>
                      </div>
                    </div>
                    <div className="badge-wrap">
                      <span className="badge rounded-pill text-bg-primary-soft">
                        {
                          lectureModels.filter(
                            (lectureModel) => lectureModel.id == lecture?.id
                          )[0]?.userCount
                        }
                        명
                      </span>
                      <span className="badge rounded-pill text-bg-primary">
                        {lecture?.service_product}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <div
          className="card-wrap column3"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {lectures.slice(0, 3).map((lecture: any) => {
            return (
              <div
                className="card pointer"
                onClick={() => history.push(`video/curriculum/${lecture?.id}`)}
              >
                <img
                  src={lecture?.card_image}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">{lecture?.title}</h5>
                  <div className="body2 mb-2">{lecture?.description}</div>
                  <div className="card-text mb-2">
                    <div className="card-caption">
                      {lecture.rating != null ? (
                        <div className="rating">
                          <span>{lecture.rating.toFixed(1)}</span>
                          <div className="star-wrap">
                            {Array.from({ length: lecture.rating }).map(
                              (_, index) => (
                                <span></span>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="price">
                        <span className="discount">
                          <span>{lecture?.origin_amount.toLocaleString()}</span>
                          원
                        </span>
                        <span>{lecture?.amount.toLocaleString()}</span>원
                      </div>
                    </div>
                  </div>
                  <div className="badge-wrap">
                    <span className="badge rounded-pill text-bg-primary-soft">
                      {
                        lectureModels.filter(
                          (lectureModel) => lectureModel.id == lecture?.id
                        )[0]?.userCount
                      }
                      명
                    </span>
                    <span className="badge rounded-pill text-bg-primary">
                      {lecture?.service_product}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <section>
      <div className="container py-120">
        <div className="section-header">
          콘엑스 인기 강의 추천
          <a className="more-btn text-gray-400" href={pages.video}>
            더보기
          </a>
        </div>
        <div className="frame">
          <ul className="nav nav-tabs slidee" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="all-tab"
                data-bs-toggle="tab"
                data-bs-target="#all-tab-pane"
                type="button"
                role="tab"
                aria-controls="all-tab-pane"
                aria-selected="true"
                onClick={() => {
                  getCourse();
                }}
              >
                전체
              </button>
            </li>
            {lectureCategories.map((category) => {
              return (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="one-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#one-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="one-tab-pane"
                    aria-selected="false"
                    onClick={() => {
                      getCourse(category);
                    }}
                    disabled={category.includes("준비")}
                  >
                    {category}
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="tab-content mt-6" id="bestcontent">
            <div className="tab-pane fade show active" tabIndex={0}>
              {lectureView()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
