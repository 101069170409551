/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import LectureRoomPresenter from "./LectureRoomPresenter";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import AlertContext from "../../contexts/AlertContext";
import { LectureCategoryModel, LectureItem } from "../../models/LectureModel";
import { api } from "../../config/api";
import { CategoryItem } from "../../models/CategoryItem";
import { FreeUseDay } from "../../constants/General";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";

interface Props {}

export default (props: Props) => {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [lecturesData, setLecturesData] = useState<LectureItem[]>();
  const [lectures, setLectures] = useState(lecturesData);
  const [lectureCategories, setLectureCategories] = useState<CategoryItem[]>();
  const history = useHistory();
  const location = useLocation();
  const categoryIndex = location.pathname.split("/")[2];
  const alert = useContext(AlertContext);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
    }

    new Promise(async () => {
      const lecturesResponse = await api.getLectureAsync();
      const lecturesData: LectureItem[] = lecturesResponse.data.lectures.map(
        (lecture: any) => {
          return {
            id: lecture.id,
            thumbnailUrl: lecture.thumbnail_url,
            description: lecture.description,
            link: lecture.link,
            created: lecture.created,
            categoryId: lecture.category_id,
            order: lecture.order,
            title: lecture.name,
          };
        }
      );
      setLecturesData(lecturesData);
      const categoryRes = await api.getLectureCategoryAsync();
      const lectureCategories: CategoryItem[] =
        categoryRes.data.lecture_categories
          ?.sort((a: any, b: any) => a.order - b.order)
          .map((lectureCategory: LectureCategoryModel) => {
            return {
              title: lectureCategory.name,
              description: lectureCategory.description,
              id: lectureCategory.id,
            };
          });
      setLectureCategories(lectureCategories);
      const categoryIndex = location.pathname.split("/")[2];
      setSelectedCategoryIndex(+categoryIndex || 0);
      setLectures(
        lecturesData.filter(
          (lecture) =>
            lecture.categoryId === lectureCategories?.[+categoryIndex]?.id
        )
      );
    });
  }, []);

  const handleClickCategory = (index: number) => {
    setSelectedCategoryIndex(index);
    setLectures(
      lecturesData?.filter(
        (lecture) => lecture.categoryId === lectureCategories?.[index]?.id
      )
    );
  };

  const handleClickPlay = (id: number) => {
    history.push(`${pages.lectureroom}/${selectedCategoryIndex}/${id}`);
    refreshLectureList(id);
  };

  const refreshLectureList = (id: number) => {
    setLectures(lecturesData?.filter((lecture) => id < lecture.id).slice(0, 3));
  };

  return (
    <main id="wrap" className="bg-light">
      <section className="page-top-banner">
        <div className="container">
          <div className="text-box">
            <div>콘엑스를 처음 경험하는</div>
            <h2>이용자들을 위한 무료강의</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container page">
          <div className="section-header">무료 강의</div>
          <div className="frame" style={{ overflow: "hidden" }}>
            <CategorySelector
              categories={lectureCategories}
              onClickCategory={handleClickCategory}
              selectedCategoryIndex={selectedCategoryIndex}
            />
            <div className="tab-content pt-6" id="lectureroomContent">
              <div
                className="tab-pane fade show active"
                id="video1-tab-pane"
                role="tabpanel"
                aria-labelledby="video1-tab"
                tabIndex={0}
              >
                <div
                  className="row-list aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {lectures?.map((lecture, index) => {
                    return (
                      <div
                        className="row-list-item pointer bg-white"
                        onClick={() => {
                          handleClickPlay(lecture.id);
                        }}
                      >
                        <div className="img-wrap">
                          <img
                            src={
                              lecture.thumbnailUrl ||
                              `https://img.youtube.com/vi/${lecture.link}/0.jpg`
                            }
                          />
                        </div>
                        <div className="flex-4">
                          <div className="title-lg">{lecture.title}</div>
                          <div className="caption mb-0">
                            {lecture.description}
                          </div>
                          <button
                            className="btn btn-outline-primary right-icon"
                            type="button"
                          >
                            강의 듣기
                            <span className="material-symbols-outlined ms-2">
                              play_circle
                            </span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="video2-tab-pane"
                role="tabpanel"
                aria-labelledby="video2-tab"
                tabIndex={0}
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="video3-tab-pane"
                role="tabpanel"
                aria-labelledby="video3-tab"
                tabIndex={0}
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="video4-tab-pane"
                role="tabpanel"
                aria-labelledby="video4-tab"
                tabIndex={0}
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="video5-tab-pane"
                role="tabpanel"
                aria-labelledby="video5-tab"
                tabIndex={0}
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="video6-tab-pane"
                role="tabpanel"
                aria-labelledby="video6-tab"
                tabIndex={0}
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="video7-tab-pane"
                role="tabpanel"
                aria-labelledby="video7-tab"
                tabIndex={0}
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="video8-tab-pane"
                role="tabpanel"
                aria-labelledby="video8-tab"
                tabIndex={0}
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
