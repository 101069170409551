import React, { useEffect } from "react";
import "./AIStatement.scss";

interface AIStatementProps {
  statement: string;
  isOpen: boolean;
  onClose: () => void;
  type?: "price" | "statement";
}

export default function AIStatement({
  statement,
  isOpen,
  onClose,
  type = "statement",
}: AIStatementProps) {
  const getPreviewUrl = () => {
    const baseUrl = process.env.REACT_APP_COST_URL;
    return type === "price"
      ? `${baseUrl}/statement-ai/price?seq=${statement}`
      : `${baseUrl}/statement-ai?seq=${statement}`;
  };

  return (
    <div className="ai-statement-container" onClick={onClose}>
      <div className="ai-statement">
        <iframe title="AIStatement" src={getPreviewUrl()} />
      </div>
    </div>
  );
}
