import { LinkOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { aiApi } from "../../config/aiApi";

interface SearchResultCitationsProps {
  citations: {
    title: string;
    description: string;
    url: string;
    image_url?: string;
  }[];
}

const SearchResultCitations: React.FC<SearchResultCitationsProps> = ({
  citations,
}) => {
  const [previews, setPreviews] = useState<
    (
      | { title: string; description: string; url: string; image_url?: string }
      | undefined
    )[]
  >([]);

  useEffect(() => {
    console.log("citations", citations);
    const loadAndFilterPreviews = async () => {
      const uniquePreviews = Array.from(
        new Map(
          (citations || [])
            .map((response) => ({
              title: response.title,
              description: response.description,
              url: response.url,
              image_url: response.image_url,
            }))
            .filter((item) => item !== undefined)
            .map((item) => [item.url, item])
        ).values()
      );
      console.log("@", uniquePreviews);
      setPreviews(uniquePreviews);
    };

    loadAndFilterPreviews();
  }, [citations]);

  return (
    <>
      <span className="search-result-content-body-citations-item-title">
        참고 자료
      </span>
      <div className="search-result-content-body-citations-item-list">
        {previews.map(
          (preview, index) =>
            preview && (
              <a
                href={preview?.url}
                target="_blank"
                rel="noreferrer"
                key={index}
                className="search-result-content-body-citations-item-list-item"
              >
                {preview?.image_url && (
                  <div className="preview-img-container">
                    <img src={preview?.image_url} alt={preview?.title} />
                  </div>
                )}
                <div className="preview-title-row">
                  <span className="preview-index">{index + 1}</span>
                  <span
                    className="preview-title"
                    style={{
                      color: preview?.title ? "" : "#1890ff",
                      cursor: preview?.title ? "default" : "pointer",
                    }}
                  >
                    {preview?.title || preview?.url}
                  </span>
                </div>
                <p className="preview-description">{preview?.description}</p>
                {preview?.title && (
                  <span className="preview-url">
                    <LinkOutlined />
                    <a
                      href={preview?.url}
                      target="_blank"
                      className="preview-url-text"
                      rel="noreferrer"
                    >
                      {preview?.url}
                    </a>
                  </span>
                )}
              </a>
            )
        )}
      </div>
    </>
  );
};

export default SearchResultCitations;
