/* eslint-disable import/no-anonymous-default-export */
import { ThumbUp } from "@material-ui/icons";
// antd는 UI를 편하게 도와주는 툴
import { Pagination } from "antd";
import SearchInput from "../../components/searchInput/SearchInput";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";
import { CategoryItem } from "../../models/CategoryItem";
import { UserBoardCategory, UserBoardListItem } from "../../models/userboard/UserBoardModel";
import styles from "./userBoardList.module.scss";
import { Link} from "react-router-dom"
import { pages } from "../../constants/PagePaths";


interface Props {
  // 유저게시판 내 탭 =[전체, 고민상담, 자료공유, 구인구직, 기타]
  userBoardItems: UserBoardListItem[];
  // CategorySelector 의 categories={props.mainTypes} 속성에 들어감
  mainTypes: CategoryItem[];
  // CategorySelector의 selectedCategoryIndex={indexOf(props.selectedCategory)} 속성에 들어감
  selectedCategory: UserBoardCategory;
  // Pagination의 current={props.currentPageIndex} 속성에 들어감
  currentPageIndex: number;
  // Pagination의 current={props.totalLength} 속성에 들어감
  totalLength: number;
  // Pagination의 current={props.pageSize} 속성에 들어감
  pageSize: number;
  // SearchInput의 defaultValue={props.searchValue} 속성에 들어감
  searchValue: string;
  // Pagination
  // 페이지 변경 :  handlePageChange 함수실행
  onPageChange: (page: number, pageSize?: number) => void;
  // CategorySelector
  // 선택한 카테고리와 type을 비교해서 페이지 변경 :handleCategoryClick 함수 실행
  onClickCategory: (index: UserBoardCategory) => void;
  // Pagination
  // 페이지 사이즈 변경 : setPageSize(size) 
  onShowSizeChange: (current: number, size: number) => void;
  // SearchInput
  // 검색 : value값에 따라 
  // isSearching.current = false 일 때, true로 바꾸고, 
  // value가 비었을 때, isSearching.current = false로 바꿈
  onSearchValueChange: (value: string) => void;
  // props.userBoardItems?.map((item,i)=> return <div onClick={()=>props.onClickStatementItem(item.id)}>)
  // 유저게시판 내 탭[전체, 고민상담, 자료공유, 구인구직, 기타]을 누르면 실행되는 handleItemClick 함수
  // handleItemClick는  id:number를 받아서 histpry.push해주는 역할
  onClickStatementItem: (id: number) => void;
  // handleWriteClick 실행->  console.log(`write click`);
  onClickWrite: () => void;
}

export default (props: Props) => {
  //console.log(props.userBoardItems)
  return (
    <div className={styles.sampleStatementPage_wrapper}>
      <div className={styles.page_title}>유저게시판</div>
      <div className={styles.under_title}>
        <div className={styles.category_description}>
          콘엑스에서 제공하는 핵심 자료를 다운로드 할 수 있습니다.
        </div>
        
        <SearchInput
          placeholder="검색어 및 키워드를 입력하세요."
          defaultValue={props.searchValue}
          onSearch={(value) => props.onSearchValueChange(value)}
        />
      </div>
      <div className={styles.category_content}>
        <CategorySelector
          categories={props.mainTypes}
          onClickCategory={(index) =>
            props.onClickCategory(
              Object.keys({UserBoardCategory})[
                index
              ] as UserBoardCategory
            )
          }
          selectedCategoryIndex={
            Object.keys(UserBoardCategory).indexOf(
            props.selectedCategory
          )
        }
        />
        <Link to={`${pages.userboardform}`}>
        <button onClick={props.onClickWrite}>
          글쓰기
        </button>
        </Link>
      </div>
      <div className={styles.result_row}>
        <div className={styles.category_title}>
          검색결과 : {props.totalLength ?? 0}개
        </div>
        <div className={styles.row_info}>
          <div className={styles.info_item}>조회수</div>
          <div className={styles.info_item}>분류</div>
          <div className={styles.info_item}>날짜</div>
        </div>
      </div>
      <div className={styles.content_container}>
        <div className={styles.list_wrapper}>
          <div className={styles.content_wrapper}>
            {/* 여기서부터 시작 */}
            {props.userBoardItems?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.sampleStatement_item}
                  onClick={() => props.onClickStatementItem(item.id)}
                >
                  <div className={`${styles.title_wrapper}`}>
                    <div className={styles.item_title}>{`${item.title}`}</div>
                    {/* <div className={styles.keyword_container}>
                      {item?.keywords?.map((keyword) => (
                        <i className={styles.item_keyword}>{keyword}</i>
                      ))}
                    </div> */}
                  </div>
                  <div className={styles.item_info_container}>
                    {/* <div className={styles.item_recomend}>
                      <div className={styles.icon}>
                        <ThumbUp />
                      </div>
      
                      {item?.recommendCount}
                    </div> */}
                    <div className={styles.item_recomend}>
                      <div className={styles.icon}>
                        {/* <ThumbUp /> */}
                      </div>
                      {item?.viewCount ?? 0}
                    </div>
                    <div className={styles.item_recomend}>{item?.category}</div>
                    <div className={styles.item_created}>
                      {item?.created.format("yyyy.MM.dd")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={styles.page}
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              pageSize={props.pageSize}
              current={props.currentPageIndex}
              total={props.totalLength}
              showSizeChanger={true}
              onChange={props.onPageChange}
              onShowSizeChange={props.onShowSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};