import React, { useEffect, useState } from "react";
import "./TableTyper.scss";
import { aiApi } from "../../config/aiApi";
import { useContext } from "react";
import { AlertContext } from "../../contexts/AlertContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import { api } from "../../config/api";

interface TableTyperProps {
  data: any;
  isPreview: boolean;
  type?: "basic" | "primary";
}

const TableTyper: React.FC<TableTyperProps> = ({ data, isPreview, type }) => {
  const alertContext = useContext(AlertContext);
  const loading = useContext(LoadingContext);
  const [statemenetList, setStatemenetList] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const tableRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const init = async () => {
      setIsVisible(false);

      setTimeout(() => {
        if (data && data?.statements) {
          setStatemenetList(data.statements);
          setIsVisible(true);
        }
      }, 300);
    };
    init();
  }, [data]);

  useEffect(() => {
    if (!isVisible || !tableRef.current) return;

    const timeoutId = setTimeout(() => {
      const rows = Array.from(
        tableRef.current?.querySelectorAll(".statement-table-row") || []
      );

      // 모든 행 초기화 및 숨김
      rows.forEach((row) => {
        row.classList.add("hidden");
        row.classList.remove("row-fade-in");
        // 인라인 스타일로 애니메이션 딜레이 적용
        (row as HTMLElement).style.animationDelay = "0s";
      });

      // 한 행씩 순차적으로 보여주기
      rows.forEach((row, index) => {
        setTimeout(() => {
          (row as HTMLElement).style.animationDelay = `${index * 0.05}s`; // 각 행마다 50ms 딜레이
          row.classList.remove("hidden");
          row.classList.add("row-fade-in");
        }, index * 50);
      });
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [isVisible, statemenetList]);

  function getSafeFloat(num: number): string {
    // convert number to string
    let str = num.toString();

    // search for decimal point
    let decimalIndex = str.indexOf(".");

    // if decimal point not found, return 0
    if (decimalIndex === -1) {
      return num.toString();
    }

    // count number of digits after decimal point
    let numDecimalPlaces = str.length - decimalIndex - 1;

    const result = numDecimalPlaces > 6 ? num.toFixed(5) : num.toString();
    return result;
  }

  function round(value: number, decimals: number): number {
    return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
  }

  function getPriceDetail(cost: number, percent: number, countValue: number) {
    if (typeof cost === "undefined") cost = 0;
    else {
      return parseInt(
        getSafeFloat(
          +getSafeFloat(+getSafeFloat(cost) * +getSafeFloat(percent)) *
            countValue
        )
      );
    }
  }
  function getUnitPriceDetail(cost: number, percent: number) {
    if (typeof cost === "undefined") cost = 0;
    return parseInt(getSafeFloat(+getSafeFloat(cost) * +getSafeFloat(percent)));
  }

  function addComma(value: number | undefined) {
    return value?.toLocaleString();
  }

  const handleExcelDownload = async () => {
    try {
      try {
        loading.showLoading("내역서를 다운로드 중입니다.");

        await api.exportExcel({ projectId: data.id, projectName: data.name });
      } catch (error) {
        console.error("error:", error);
        alertContext.showAlert(
          "내역서 다운로드 중 오류가 발생했습니다. \n 고객센터에 문의해주세요."
        );
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      loading.closeLoading();
    }
  };

  if (!isVisible) return null;

  return (
    isPreview && (
      <div
        ref={tableRef}
        className={`table-typer-container ${
          isVisible ? "fade-in" : "fade-out"
        }`}
      >
        <div className="statement-table">
          {/* 테이블 헤더 */}
          <div className="statement-table-header ">
            <div
              className={`header-row  ${
                type === "primary" ? "main-header" : "basic-main-header"
              }`}
            >
              <div className="statement-table-header-item">품명</div>
              <div className="statement-table-header-item">규격</div>
              <div className="statement-table-header-item">단위</div>
              <div className="statement-table-header-item column-group">
                합계
              </div>
              <div className="statement-table-header-item column-group">
                재료비
              </div>
              <div className="statement-table-header-item column-group">
                노무비
              </div>
              <div className="statement-table-header-item column-group">
                경비
              </div>
            </div>
          </div>
          {statemenetList.map((item, topIndex) => (
            <>
              {/* 대분류 */}
              <div
                key={topIndex}
                className={`statement-table-row hidden ${
                  type === "primary" ? "title-row" : "basic-title-row"
                }`}
                data-row-id={`${topIndex}`}
              >
                <div className="statement-table-cell bold">
                  {item.corporation}
                </div>
                <div className="statement-table-cell bold"></div>
                <div className="statement-table-cell bold"></div>
                <div className="statement-table-cell bold">
                  {addComma(item.cost_total)}
                </div>
                <div className="statement-table-cell bold">
                  {addComma(item.material_cost_total)}
                </div>
                <div className="statement-table-cell bold">
                  {addComma(item.labor_cost_total)}
                </div>
                <div className="statement-table-cell bold">
                  {addComma(item.expense_cost_total)}
                </div>
              </div>

              {/* 중분류 */}
              {item.children?.map((child: any, midIndex: number) => (
                <>
                  <div
                    key={midIndex}
                    className={`statement-table-row hidden ${
                      type === "primary" ? "subtitle-row" : "basic-subtitle-row"
                    }`}
                    data-row-id={`${topIndex}_${midIndex}`}
                  >
                    <div className="statement-table-cell">{child.work}</div>
                    <div className="statement-table-cell">
                      {child.specification}
                    </div>
                    <div className="statement-table-cell">{child.unit}</div>
                    <div className="statement-table-cell">
                      {addComma(child.cost_total)}
                    </div>
                    <div className="statement-table-cell">
                      {addComma(child.material_cost_total)}
                    </div>
                    <div className="statement-table-cell">
                      {addComma(child.labor_cost_total)}
                    </div>
                    <div className="statement-table-cell">
                      {addComma(child.expense_cost_total)}
                    </div>
                  </div>

                  {/* 소분류 */}
                  {child.detail?.map((detail: any, detailIndex: number) => (
                    <div
                      key={detailIndex}
                      className="statement-table-row hidden"
                      data-row-id={`${topIndex}_${midIndex}_${detailIndex}`}
                    >
                      <div className="statement-table-cell">{detail.name}</div>
                      <div className="statement-table-cell">
                        {detail.standard}
                      </div>
                      <div className="statement-table-cell">{detail.unit}</div>
                      <div className="statement-table-cell">
                        {addComma(
                          getUnitPriceDetail(
                            (detail.item
                              ? detail.item.materialCost
                              : detail.materialCost) || 0,
                            1
                          ) +
                            getUnitPriceDetail(
                              (detail.item
                                ? detail.item.laborCost
                                : detail.laborCost) || 0,
                              1
                            ) +
                            getUnitPriceDetail(
                              (detail.item
                                ? detail.item.expenseCost
                                : detail.expenseCost) || 0,
                              1
                            )
                        )}
                      </div>
                      <div className="statement-table-cell">
                        {addComma(
                          getPriceDetail(
                            (detail.item
                              ? detail.item.materialCost
                              : detail.materialCost) || 0,
                            1,
                            round(detail.count_value, 5)
                          )
                        )}
                      </div>
                      <div className="statement-table-cell">
                        {addComma(
                          getPriceDetail(
                            (detail.item
                              ? detail.item.laborCost
                              : detail.laborCost) || 0,
                            1,
                            round(detail.count_value, 5)
                          )
                        )}
                      </div>
                      <div className="statement-table-cell">
                        {addComma(
                          getPriceDetail(
                            (detail.item
                              ? detail.item.expenseCost
                              : detail.expenseCost) || 0,
                            1,
                            round(detail.count_value, 5)
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </>
          ))}
        </div>
        <div className="statement-table-footer">
          <div className="statement-table-footer-item">
            <button
              onClick={handleExcelDownload}
              className="statement-table-footer-item-button"
            >
              내역서 다운로드
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default TableTyper;
