import { Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { api } from "../../config/api";
import { Link, useHistory } from "react-router-dom";
import { util } from "../../config/util";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { Cookies } from "../../constants/Cookies";
import { useGA4React } from "ga-4-react";
import GlobalEnvironments from "../../modules/globalEnvironment/GlobalEnvironment";

function LoginPage(props: any) {
  const [autoLogin, setAutoLogin] = useState(false);
  const [saveId, setSaveId] = useState(
    util.getCookie(Cookies.loginId) ? true : false
  );
  const [id, setId] = useState<string>(util.getCookie(Cookies.loginId) || "");
  const [pw, setPw] = useState<string>();
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  const ga4 = useGA4React();

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      history.replace(pages.index);
    }

    if (!window.Kakao.isInitialized()) {
      window.Kakao.init("4e6c55bc6919807933f9f9c2620e7e9d");
    }
  }, []);

  const changeAutoLogin = () => {
    setAutoLogin(!autoLogin);
  };
  const changeSaveId = () => {
    setSaveId(!saveId);
  };
  const changeId = (event: any) => {
    const value: any = event.target.value;
    if (value.length <= 100) setId(value);
  };
  const changePw = (event: any) => {
    const value: any = event.target.value;
    if (value.length <= 100) setPw(value);
  };

  const socialLogin = (social: string) => {
    if (social === "KAKAO") {
      window.Kakao.Auth.authorize({
        redirectUri: GlobalEnvironments.getAppUrl()+"oauth2/kakao",
      });
    } else if (social === "NAVER") {
      const clientId = "5KdLca8FOBTswLiRvb6v";
      const redirectUri = GlobalEnvironments.getAppUrl()+"oauth2/naver";
      const naverLoginUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
      window.location.href = naverLoginUrl;
    } else {
      //GOOGLE
      const clientId =
        "419125396470-2rt62duahcpf2odq0nggbm8tskui2rjo.apps.googleusercontent.com";
      const redirectUri = GlobalEnvironments.getAppUrl()+"oauth2/google";
      const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email+profile`;
      window.location.href = googleLoginUrl;
    }
  };

  const login = async () => {
    if (!id || !pw) {
      showAlert("아이디 혹은 비밀번호를 입력해주세요.");
      return;
    }
    try {
      const res = await api.loginWithOption(
        id || "",
        pw || "",
        autoLogin,
        saveId
      );
      const status = res.status;
      if (status === 200) {
        props.checkUser();
        history.replace(pages.index);
        if (ga4 instanceof Object) {
          ga4.gtag("config", process.env.REACT_API_GTAG_ID, {
            user_id: res.data.key,
          });
        }
      }
    } catch (error: any) {
      if (error.hasOwnProperty("response")) {
        const status = error.response?.status;
        if (status === 400) {
          showAlert("아이디/비밀번호가 맞지 않습니다.", undefined, true);
        } else {
          showAlert("에러가 발생하였습니다.");
        }
      } else {
        showAlert("에러가 발생하였습니다.");
      }
    }
  };

  const handleFindAccount = () => {
    history.push(pages.findaccount);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <main id="wrap" className="bg-light-md">
      <article>
        <section>
          <div className="container page" onKeyPress={handleKeyPress}>
            <div className="section-header">로그인</div>
            <div
              className="col-md-4 mt-3 mx-auto form-wrap"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="text-dark mb-6">
                콘엑스 로그인 후 더욱 편리한 서비스를 이용해 보세요.
              </h4>

              <form className="row g-3 needs-validation">
                <div className="col-md-12">
                  <label htmlFor="validationCustom01" className="form-label">
                    <span className="material-symbols-outlined">person</span>{" "}
                    이메일
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="이메일(아이디)을 입력해주세요"
                    required
                    onChange={changeId}
                    value={id || ""}
                  />
                  <div className="invalid-feedback">
                    정확한 이메일(아이디)을 입력해주세요.
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="validationCustom02" className="form-label">
                    <span className="material-symbols-outlined">lock</span>
                    비밀번호
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="validationCustom02"
                    placeholder="비밀번호를 입력해주세요"
                    required
                    onChange={changePw}
                    value={pw || ""}
                  />
                  <div className="invalid-feedback">
                    정확한 비밀번호를 입력해주세요
                  </div>
                </div>

                <div className="col-12 mt-4">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="autologin"
                      value="option1"
                      checked={autoLogin}
                      onChange={changeAutoLogin}
                    />
                    <label className="form-check-label" htmlFor="autologin">
                      자동로그인
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="idsave"
                      value="option2"
                      checked={saveId}
                      onChange={changeSaveId}
                    />
                    <label className="form-check-label" htmlFor="idsave">
                      {" "}
                      아이디 저장
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-xlg btn-primary w-100"
                    type="button"
                    onClick={login}
                  >
                    로그인
                  </button>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-xlg w-100 kakao-login gap-2 d-flex justify-content-center align-items-center"
                    type="button"
                    onClick={() => socialLogin("KAKAO")}
                  >
                    <img src="images/icons/kakao_logo.svg" />
                    KAKAO 로그인
                  </button>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-xlg btn-primary w-100 naver-login gap-2 d-flex justify-content-center align-items-center"
                    type="button"
                    onClick={() => socialLogin("NAVER")}
                  >
                    <img
                      style={{ width: 28, height: 28 }}
                      src="images/icons/naver_logo.svg"
                    />
                    NAVER 로그인
                  </button>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-xlg btn-primary w-100 google-login gap-2 d-flex justify-content-center align-items-center"
                    type="button"
                    onClick={() => socialLogin("GOOGLE")}
                  >
                    <img
                      style={{ width: 28, height: 28 }}
                      src="images/icons/google_logo.svg"
                    />
                    GOOGLE 로그인
                  </button>
                </div>
              </form>

              <div className="border-1 my-4"></div>

              <div>
                <div className="mb-3 d-flex align-items-center gap-2">
                  <span className="material-symbols-outlined text-info">
                    error
                  </span>
                  <span>
                    <a
                      onClick={handleFindAccount}
                      className="text-dark fw-bold"
                    >
                      아이디
                    </a>{" "}
                    또는{" "}
                    <a
                      onClick={handleFindAccount}
                      className="text-dark fw-bold"
                    >
                      비밀번호
                    </a>
                    를 잊으셨나요?{" "}
                  </span>
                </div>
                <a
                  className="btn btn-md btn-primary-soft w-100"
                  href={pages.signup}
                >
                  회원가입
                </a>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}

export default LoginPage;
