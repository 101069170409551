import {
  LoadingOutlined,
  SearchOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import "./searchResult.scss";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AiSearchInput from "../../components/AiSearchInput/AiSearchInput";
import { aiApi } from "../../config/aiApi";
import MarkdownTyper from "../../components/MarkdownTyper";
import { AiChatCompletion, Message } from "../../models/aiSearchModel";
import SearchResultAside from "./SearchResultAside";
import SearchResultCitations from "./SearchResultCitations";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import Modal from "antd/lib/modal";
import { Form, Input, Button } from "antd";
import { QuotaInfo } from "../../models/quotaModel";
import { util } from "../../config/util";

interface Conversation {
  id: number;
  title: string;
  created_at: string;
}
interface ExtendedMessage extends Message {
  new?: boolean;
  message_id?: number;
}

// 새로운 인터페이스 추가
interface MessageFeedback {
  message_index: number;
  feedback_type: "좋아요" | "별로에요";
  has_submitted: boolean;
}

export default function SearchResult() {
  const [searchValue, setSearchValue] = useState<string>();
  const [searchResult, setSearchResult] = useState<any>([]);
  const [searchUserMessages, setSearchUserMessages] = useState<Message[]>([]);
  const [searchAssistantMessages, setSearchAssistantMessages] = useState<
    ExtendedMessage[]
  >([]);

  const [historyConversation, setHistoryConversation] = useState<
    Conversation[]
  >([]);
  const [loading, setLoading] = useState(true);
  const searchResultRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const params = useRef<any>({});
  const [feedbackModalVisible, setFeedbackModalVisible] =
    useState<boolean>(false);
  const [selectedFeedback, setSelectedFeedback] = useState<
    "좋아요" | "별로에요" | null
  >(null);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number | null>(
    null
  );
  const [form] = Form.useForm();
  const [messageFeedbacks, setMessageFeedbacks] = useState<MessageFeedback[]>(
    []
  );
  const [searchCategory, setSearchCategory] = useState<
    {
      title: string;
      active: boolean;
      api: string;
    }[]
  >([]);
  const [quota, setQuota] = useState<QuotaInfo>({
    perplexity: "",
    claude_response_part1: "",
    claude_response_part2: "",
    analyze_project_statements: "",
    project_recommendation: "",
  });
  const history = useHistory();

  const fetchData = useCallback(
    async (result: string) => {
      if (!result) {
        return;
      }
      setLoading(true);

      const userMessage: Message = { role: "user", content: result };

      // 메시지를 교차로 배열하기
      const messages: Message[] = [];
      const maxLength = Math.max(
        searchUserMessages.length,
        searchAssistantMessages.length
      );

      for (let i = 0; i < maxLength; i++) {
        if (searchUserMessages[i]) {
          messages.push(searchUserMessages[i]);
        }
        if (searchAssistantMessages[i]) {
          messages.push(searchAssistantMessages[i]);
        }
      }

      // 새로운 유저 메시지 추가
      messages.push(userMessage);

      try {
        const res = await aiApi.chatCompletions(messages);
        console.log("res", res);
        if (res.messages) {
          alert(res.messages);
          return;
        }

        // AI 응답을 먼저 표시
        if (res && res.choices) {
          const assistantMessage = {
            role: "assistant",
            content: res.choices[0].message.content,
            new: true,
          };

          // 응답 메시지 상태 업데이트 (크롤링 결과 없이)
          setSearchAssistantMessages((prevMessages) => [
            ...prevMessages,
            {
              ...assistantMessage,
              message_id: -1, // 임시 ID
            },
          ]);

          // 빈 크롤링 결과 추가 (나중에 업데이트됨)
          setSearchResult((prevResult: any) => {
            const updatedResult = [...prevResult];
            updatedResult.push([]);
            return updatedResult;
          });

          // 비동기적으로 크롤링 결과 처리
          const createFormattedData = async (
            citations: any[],
            chatId: number
          ) => {
            try {
              // 각 인용에 대해 Promise.all 사용하되 타임아웃 적용
              const previewsPromises = citations.map(async (data) => {
                try {
                  // 3초 타임아웃 설정
                  const result = await Promise.race([
                    aiApi.getLinkPreview(data),
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve({ tags: {}, url: data });
                      }, 3000);
                    }),
                  ]);
                  return result;
                } catch (error) {
                  console.error("링크 크롤링 중 오류:", error);
                  // 오류 발생 시 기본 객체 반환
                  return { tags: {}, url: data };
                }
              });

              // 모든 프로미스 병렬 실행
              const previewsData = await Promise.all(previewsPromises);

              console.log("previewsData", previewsData);
              const formattedData = previewsData.map((item, index) => {
                const tags = item.tags || {};
                return {
                  chat_id: chatId,
                  title: tags.og_title || tags.title || null,
                  description: tags.og_description || tags.description || null,
                  url: tags.og_url || tags.url || citations[index],
                  image_url: tags.og_image || tags.image || null,
                };
              });

              // 크롤링 결과 업데이트
              setSearchResult((prevResult: any) => {
                const updatedResult = [...prevResult];
                updatedResult[updatedResult.length - 1] = formattedData;
                return updatedResult;
              });

              // 메시지 ID 업데이트 및 크롤링 결과 저장 (기존 코드와 동일)
              if (params.current.seq) {
                const postMessages = await aiApi.postMessage({
                  conversation_id: params.current.seq,
                  ...assistantMessage,
                });

                // 메시지 ID 업데이트
                setSearchAssistantMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  const lastIndex = updatedMessages.length - 1;
                  updatedMessages[lastIndex] = {
                    ...updatedMessages[lastIndex],
                    message_id: postMessages.message_id,
                  };
                  return updatedMessages;
                });

                // formattedData의 chat_id를 업데이트
                const updatedFormattedData = formattedData.map((item) => ({
                  ...item,
                  chat_id: postMessages.message_id,
                }));

                const postCompletion = await aiApi.postCompletion(
                  updatedFormattedData
                );

                // 크롤링 결과 업데이트
                setSearchResult((prevResult: any) => {
                  const updatedResult = [...prevResult];
                  updatedResult[updatedResult.length - 1] =
                    updatedFormattedData;
                  return updatedResult;
                });
              } else {
                // 대화 ID가 없는 경우 (새 대화)
                const formattedData = previewsData.map((item, index) => {
                  const tags = item.tags || {};
                  return {
                    chat_id: -1,
                    title: tags.og_title || tags.title || null,
                    description:
                      tags.og_description || tags.description || null,
                    url: tags.og_url || tags.url || citations[index],
                    image_url: tags.og_image || tags.image || null,
                  };
                });

                // 크롤링 결과 업데이트
                setSearchResult((prevResult: any) => {
                  const updatedResult = [...prevResult];
                  updatedResult[updatedResult.length - 1] = formattedData;
                  return updatedResult;
                });
              }
            } catch (error) {
              console.error("크롤링 결과 처리 중 오류:", error);
            }
          };

          // 비동기적으로 크롤링 처리 시작
          createFormattedData(res.citations, -1);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      const quota = await util.getQuota();
      setQuota(quota);
      setLoading(false);
    },
    [searchUserMessages, searchAssistantMessages]
  );

  // 피드백 상태 초기화 함수 추가
  const initializeFeedbacks = useCallback(async (seq: string) => {
    try {
      const res = await aiApi.getConversation();
      const messages = await aiApi.getMessage(seq as string);

      // 메시지 필터링을 먼저 수행
      const userMessages = messages.messages.filter(
        (item: any) => item.role === "user"
      );
      const assistantMessages = messages.messages.filter(
        (item: any) => item.role === "assistant"
      );

      // assistant 메시지에 대한 completion 한 번만 호출
      const completionMessages = await Promise.all(
        assistantMessages.map(async (item: any) => {
          const completion = await aiApi.getCompletion(item.message_id);
          return completion;
        })
      );

      const nestedCompletions = completionMessages.map(
        (message) => message?.completions || []
      );

      setSearchResult(nestedCompletions);
      setHistoryConversation(
        res.conversations.map((item: any) => ({
          conversation_id: item.conversation_id,
          title: item.title,
          created_at: item.created_at,
        }))
      );

      // 메시지 ID와 인덱스 매핑 생성
      const messageIndexMap = new Map();
      assistantMessages.forEach((message: any, index: number) => {
        messageIndexMap.set(message.message_id, index);
      });

      // 피드백 초기화 시 매핑된 인덱스 사용
      const response = await aiApi.getFeedbacks(seq as string, "chat");
      const feedbackArray = response?.feedbacks || [];

      setMessageFeedbacks(
        feedbackArray.map((feedback: any) => ({
          message_index: messageIndexMap.get(feedback.message_id) || 0,
          feedback_type: feedback.feedback_type,
          has_submitted: Boolean(feedback.content),
        }))
      );

      setSearchUserMessages(userMessages);
      setSearchAssistantMessages(
        assistantMessages.map((item: any) => ({
          ...item,
          new: false,
        }))
      );

      const lastMessage = messages.messages[messages.messages.length - 1];
      if (lastMessage.role === "user") {
        await fetchData(lastMessage.content);
      }
      return;
    } catch (error) {
      console.error("피드백 조회 오류:", error);
    }
  }, []);

  useEffect(() => {
    const getQuota = async () => {
      const quota = await util.getQuota();
      setQuota(quota);
      setSearchCategory([
        {
          title: "기본 웹 검색",
          active: true,
          api: "perplexity",
        },
      ]);
    };
    getQuota();
    const init = async () => {
      const parsed = queryString.parse(location.search);
      params.current = parsed;
      const query = parsed.value;
      const seq = parsed.seq;

      if (query) {
        setSearchValue(query as string);
        return;
      }

      if (seq) {
        await initializeFeedbacks(seq as string);
      }
    };
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (searchResultRef.current && stickyTopElement) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchResultRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    updateHeight();
    init();
  }, [location.search]);

  useEffect(() => {
    if (searchValue && !params.current.seq) {
      console.log("searchvalue", searchValue);
      const userMessage: Message = { role: "user", content: searchValue };
      setSearchUserMessages((prevMessages) => [...prevMessages, userMessage]);
      fetchData(searchValue);
    }
  }, [searchValue]);

  async function onSearchSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent | null,
    result: string
  ) {
    // 새로운 사용자 메시지 생성
    const userMessage: Message = { role: "user", content: result };

    // 사용자 메시지 상태 업데이트
    setSearchUserMessages((prevMessages) => [...prevMessages, userMessage]);

    if (params.current.seq) {
      try {
        await aiApi.postMessage({
          conversation_id: params.current.seq,
          role: "user",
          content: result,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    // 새로운 질문 위치로 스크롤
    setTimeout(() => {
      const elements = document.querySelectorAll(".search-result-content-body");
      if (elements.length > 0) {
        const lastElement = elements[elements.length - 1];
        lastElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 100);

    // fetchData 호출하여 AI 응답 받기
    await fetchData(result);
  }
  const complete = (el: HTMLDivElement) => {
    window.scrollTo({
      top: el?.offsetTop - 120,
      behavior: "smooth",
    });
    setLoading(false);
  };

  const handleFeedback = async (type: "좋아요" | "별로에요", index: number) => {
    try {
      const messageId = searchAssistantMessages[index]?.message_id;

      if (!messageId) {
        console.error("메시지 ID를 찾을 수 없습니다.");
        return;
      }

      await aiApi.submitFeedbackType({
        conversation_id: params.current.seq,
        message_index: index,
        feedback_type: type,
        type: "chat",
      });

      // 동일한 메시지에 대한 이전 피드백을 제거하고 새 피드백 추가
      setMessageFeedbacks((prev) => [
        ...prev.filter((feedback) => feedback.message_index !== index),
        {
          message_index: index,
          feedback_type: type,
          has_submitted: false,
        },
      ]);

      setSelectedFeedback(type);
      setCurrentMessageIndex(index);
      setFeedbackModalVisible(true);
    } catch (error) {
      console.error("피드백 제출 오류:", error);
    }
  };

  const handleFeedbackSubmit = async (values: any) => {
    try {
      if (currentMessageIndex !== null) {
        await aiApi.submitFeedbackContent({
          conversation_id: params.current.seq,
          message_index: currentMessageIndex,
          feedback_type: selectedFeedback || "",
          feedback_content: values.feedback,
          type: "chat",
        });

        // 피드백 제출 상태 업데이트
        setMessageFeedbacks((prev) =>
          prev.map((feedback) =>
            feedback.message_index === currentMessageIndex
              ? { ...feedback, has_submitted: true }
              : feedback
          )
        );
      }
      setFeedbackModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("피드백 내용 제출 오류:", error);
    }
  };

  useEffect(() => {
    console.log("messageFeedbacks", messageFeedbacks);
  }, [messageFeedbacks]);

  return (
    <div className="search-result bg-light-md" ref={searchResultRef}>
      <SearchResultAside />
      <div className="search-result-content">
        {searchUserMessages.map((message, index) =>
          message.role === "user" ? (
            <div key={index} className="search-result-content-body">
              <div className="search-result-content-body-item-list">
                <div className="search-result-content-body-title">
                  <span className="search-item-title">{message.content}</span>
                </div>
                <div className="search-result-content-body-item">
                  <span className="search-result-content-body-item-title">
                    <SearchOutlined />
                    <span>답변</span>
                  </span>
                  <span className="search-item">
                    {searchAssistantMessages &&
                    searchAssistantMessages[index] ? (
                      <MarkdownTyper
                        isNew={searchAssistantMessages[index].new || false}
                        markdown={searchAssistantMessages[index].content}
                        onComplete={(el: HTMLDivElement) => {
                          complete(el);
                        }}
                      />
                    ) : (
                      <span className="ant-design-icon">
                        <LoadingOutlined />
                      </span>
                    )}
                  </span>
                  <div className="feedback-container">
                    <span className="feedback-text">
                      이 대화가 만족스러우셨나요?
                    </span>
                    <div className="feedback-buttons">
                      <button
                        onClick={() => handleFeedback("좋아요", index)}
                        className={`feedback-button ${
                          messageFeedbacks.some(
                            (f) =>
                              f.message_index === index &&
                              f.feedback_type === "좋아요" &&
                              f ===
                                messageFeedbacks.find(
                                  (fb) => fb.message_index === index
                                )
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <LikeOutlined />
                      </button>
                      <button
                        onClick={() => handleFeedback("별로에요", index)}
                        className={`feedback-button ${
                          messageFeedbacks.some(
                            (f) =>
                              f.message_index === index &&
                              f.feedback_type === "별로에요" &&
                              f ===
                                messageFeedbacks.find(
                                  (fb) => fb.message_index === index
                                )
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <DislikeOutlined />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-result-content-body-citations">
                <SearchResultCitations citations={searchResult[index]} />
              </div>
            </div>
          ) : null
        )}
        <div className="search-result-content-footer">
          <AiSearchInput
            loading={loading}
            onSearchSubmit={onSearchSubmit}
            placeholder="추가 질문을 입력해주세요. 예) 조금 더 자세하게 알려줄 수 있나요?,"
            tooltipText="현재 무료로 PRO 기능을 이용중입니다<br>최적화된 답변을 받을 수 있습니다."
            quota={quota}
            searchCategory={searchCategory}
          />
        </div>
      </div>
      <Modal
        title="피드백을 남겨주세요"
        open={feedbackModalVisible}
        onCancel={() => setFeedbackModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleFeedbackSubmit}>
          <Form.Item
            name="feedback"
            rules={[{ required: true, message: "피드백 내용을 입력해주세요" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="답변에 대한 의견을 자유롭게 작성해주세요."
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              제출하기
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
