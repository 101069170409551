import React, { useEffect, useState, useCallback } from "react";
import AIStatement from "../../../components/AIStatement/AIStatement";
import { aiApi } from "../../../config/aiApi";
import { AlertContext } from "../../../contexts/AlertContext";
import { useContext } from "react";
import { LoadingContext } from "../../../contexts/LoadingContext";
import {
  CaretRightOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";

interface StatementCitationsProps {
  labels: any;
  citations: any;
  onPreviewData?: (data: any) => void;
  onItemizedSelected?: (data: any, index?: number) => void;
  onCheckItemizedCost?: (id: string, checked: boolean) => void;
  onCheckPriceCalculation?: (id: string, checked: boolean) => void;
  resetCheckedItems?: boolean;
  onResetComplete?: () => void;
}

interface GroupedItem {
  title: string;
  items: {
    id: string;
    specification?: string;
    labelIndex: number;
  }[];
  hasProject: string;
  startLabelIndex: number;
}

const StatementCitations: React.FC<StatementCitationsProps> = ({
  labels,
  citations,
  onPreviewData,
  onItemizedSelected,
  onCheckItemizedCost,
  onCheckPriceCalculation,
  resetCheckedItems,
  onResetComplete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [checkedItemizedCosts, setCheckedItemizedCosts] = useState<string[]>(
    []
  );
  const [checkedPriceCalculations, setCheckedPriceCalculations] = useState<
    string[]
  >([]);
  const alertContext = useContext(AlertContext);
  const loading = useContext(LoadingContext);
  const [selectedType, setSelectedType] = useState<"price" | "statement">(
    "statement"
  );

  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const [visibleItems, setVisibleItems] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handlePreview = (
    id: string,
    type: "price" | "statement" = "statement",
    name: string,
    labelIndex: number,
    groupIndex: number
  ) => {
    const data = {
      id,
      type,
      name,
      label: labels?.[labelIndex],
    };
    if (onItemizedSelected) {
      console.log("@@labels", labels);
      console.log("label", labels?.[labelIndex]);
      onItemizedSelected(data, groupIndex);
    }
  };
  const handleProjectPreview = async (
    e: React.MouseEvent,
    id?: string,
    name?: string,
    type?: "price_calculation" | "itemized"
  ) => {
    e.stopPropagation();

    // id가 없는 경우 early return
    if (!id) {
      console.warn("Project ID is undefined");
      if (onPreviewData) {
        onPreviewData({
          item_name: name,
          message: `${name || "알 수 없는 프로젝트"} 프로젝트가 없습니다.`,
        });
      }
      return;
    }

    try {
      const response = await aiApi.getProjectPreview(id, name, type);
      console.log("response", response);
      if (onPreviewData) {
        onPreviewData(response);
      }
    } catch (error) {
      console.error("Project preview error:", error);
      if (onPreviewData) {
        onPreviewData({
          item_name: name,
          message: `${name || "알 수 없는 프로젝트"} 프로젝트가 없습니다.`,
        });
      }
    }
  };
  const handleItemizedCheckboxChange = (id: string) => {
    setCheckedItemizedCosts((prev) => {
      const newChecked = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      // 부모 컴포넌트에 체크 상태 전달
      if (onCheckItemizedCost) {
        onCheckItemizedCost(id, !prev.includes(id));
      }

      return newChecked;
    });
  };

  const handlePriceCheckboxChange = (id: string) => {
    setCheckedPriceCalculations((prev) => {
      const newChecked = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      // 부모 컴포넌트에 체크 상태 전달
      if (onCheckPriceCalculation) {
        onCheckPriceCalculation(id, !prev.includes(id));
      }

      return newChecked;
    });
  };

  const getCitationsArray = (array: any) => {
    if (!array) return [];
    if (Array.isArray(array)) return array;
    if (typeof array === "string") {
      try {
        const parsed = JSON.parse(array);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch {
        return array.split(",").map((item: any) => item.trim());
      }
    }
    return [array]; // 단일 값인 경우 배열로 변환
  };

  const itemizedArray = getCitationsArray(citations?.itemized_cost_id);
  const priceArray = getCitationsArray(citations?.price_calculation_id);
  const itemizedHasProject = getCitationsArray(
    citations?.itemized_has_project
  ).map((value: any) => {
    if (typeof value === "boolean") return value;
    if (typeof value === "string") return value.toLowerCase() === "true";
    return false;
  });
  const priceHasProject = getCitationsArray(citations?.price_has_project).map(
    (value: any) => {
      if (typeof value === "boolean") return value;
      if (typeof value === "string") return value.toLowerCase() === "true";
      return false;
    }
  );

  // 제목과 규격을 분리하는 함수
  const separateTitleAndSpec = (fullTitle: string) => {
    const parts = fullTitle.split(" - ");
    return {
      title: parts[0],
      specification: parts.slice(1).join(" - "), // 규격에 '-'가 포함될 수 있으므로
    };
  };

  // 그룹화 함수 수정
  const groupItems = (
    items: string[],
    titles: string[] = [],
    hasProject: any[] = []
  ) => {
    const groups: { [key: string]: GroupedItem } = {};
    let currentIndex = 0;

    items.forEach((id, index) => {
      if (!id || !titles[index]) return;

      const { title, specification } = separateTitleAndSpec(titles[index]);

      if (!groups[title]) {
        groups[title] = {
          title,
          items: [],
          hasProject: String(hasProject[index]),
          startLabelIndex: currentIndex, // 그룹의 시작 인덱스 저장
        };
      }

      groups[title].items.push({
        id,
        specification,
        labelIndex: currentIndex, // 각 아이템의 라벨 인덱스 저장
      });

      currentIndex++;
    });
    return Object.values(groups);
  };

  const toggleSection = (title: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  useEffect(() => {
    if (!citations) return;

    const itemizedIds = getCitationsArray(citations?.itemized_cost_id).map(
      (id: any) => `itemized-${id}`
    );

    const priceIds = getCitationsArray(citations?.price_calculation_id).map(
      (id: any) => `price-${id}`
    );

    // 이전 상태 초기화
    setVisibleItems({});
    let currentIndex = 0;
    let currentPriceIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex < itemizedIds.length) {
        setVisibleItems((prev) => ({
          ...prev,
          [itemizedIds[currentIndex]]: true,
        }));
        currentIndex++;
      } else if (currentPriceIndex < priceIds.length) {
        setVisibleItems((prev) => ({
          ...prev,
          [priceIds[currentPriceIndex]]: true,
        }));
        currentPriceIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [citations]);

  useEffect(() => {
    if (resetCheckedItems) {
      setCheckedItemizedCosts([]);
      setCheckedPriceCalculations([]);
      if (onResetComplete) {
        onResetComplete();
      }
    }
  }, [resetCheckedItems, onResetComplete]);

  return (
    <>
      <div className="search-result-content-body-citations-item-list">
        {itemizedArray.length > 0 && (
          <div className="citation-section">
            {itemizedArray[0] !== "" && (
              <div className="citation-item-title">
                <span>일위대가 미리보기</span>
              </div>
            )}
            {groupItems(
              itemizedArray,
              citations?.itemized_cost_titles || [],
              itemizedHasProject
            ).map((group, groupIndex) => (
              <div key={groupIndex}>
                {group.items.length === 1 ? (
                  <div
                    className={`citation-single-item fade-in-item ${
                      visibleItems[`itemized-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <div className="citation-preview-button-wrapper">
                      <input
                        type="checkbox"
                        checked={checkedItemizedCosts.includes(
                          group.items[0].id
                        )}
                        onChange={() =>
                          handleItemizedCheckboxChange(group.items[0].id)
                        }
                      />
                      <span
                        className="preview-icon-wrapper"
                        onClick={() =>
                          handlePreview(
                            group.items[0].id,
                            "statement",
                            group.title,
                            group.items[0].labelIndex,
                            groupIndex
                          )
                        }
                      >
                        <SearchOutlined />
                        <span className="tooltip">상세 정보 보기</span>
                      </span>
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={(e) =>
                            handleProjectPreview(
                              e,
                              group.items[0].id,
                              group.title,
                              "itemized"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                    </div>
                    <label
                      className="checkbox-wrapper"
                      onClick={() =>
                        handlePreview(
                          group.items[0].id,
                          "statement",
                          group.title,
                          group.items[0].labelIndex,
                          groupIndex
                        )
                      }
                    >
                      <span>{group.title}</span>
                    </label>
                  </div>
                ) : (
                  <div
                    className={`citation-accordion fade-in-item ${
                      visibleItems[`itemized-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <div className="citation-accordion-header">
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={(e) =>
                            handleProjectPreview(
                              e,
                              group.items[0].id,
                              group.title,
                              "itemized"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                      <span
                        style={{ display: "flex", flex: 1 }}
                        onClick={() => toggleSection(group.title)}
                      >
                        {group.title}
                      </span>

                      <span
                        onClick={() => toggleSection(group.title)}
                        className={`arrow ${
                          expandedSections[group.title] ? "expanded" : ""
                        }`}
                      >
                        <CaretRightOutlined />
                      </span>
                    </div>
                    <div
                      className={`citation-accordion-content ${
                        expandedSections[group.title] ? "expanded" : ""
                      }`}
                    >
                      {group.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="citation-spec-item">
                          <div className="citation-preview-button-wrapper">
                            <input
                              type="checkbox"
                              checked={checkedItemizedCosts.includes(item.id)}
                              onChange={() =>
                                handleItemizedCheckboxChange(item.id)
                              }
                            />
                            <span
                              className="preview-icon-wrapper"
                              onClick={() =>
                                handlePreview(
                                  item.id,
                                  "statement",
                                  group.title,
                                  item.labelIndex,
                                  groupIndex
                                )
                              }
                            >
                              <SearchOutlined />
                              <span className="tooltip">상세 정보 보기</span>
                            </span>
                          </div>
                          <label
                            className="checkbox-wrapper"
                            onClick={() =>
                              handlePreview(
                                item.id,
                                "statement",
                                group.title,
                                item.labelIndex,
                                groupIndex
                              )
                            }
                          >
                            <span>
                              {group.title} - {item.specification}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {priceArray.length > 0 && (
          <div className="citation-section">
            {priceArray[0] !== "" && (
              <div className="citation-item-title">
                <span>단가산출 미리보기</span>
              </div>
            )}
            {groupItems(
              priceArray,
              citations?.price_calculation_titles || [],
              priceHasProject
            ).map((group, groupIndex) => (
              <div key={groupIndex}>
                {group.items.length === 1 ? (
                  <div
                    className={`citation-single-item fade-in-item  ${
                      visibleItems[`price-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <div className="citation-preview-button-wrapper">
                      <input
                        type="checkbox"
                        checked={checkedPriceCalculations.includes(
                          group.items[0].id
                        )}
                        onChange={() =>
                          handlePriceCheckboxChange(group.items[0].id)
                        }
                      />
                      <span
                        className="preview-icon-wrapper"
                        onClick={() =>
                          handlePreview(
                            group.items[0].id,
                            "price",
                            group.title,
                            group.items[0].labelIndex,
                            groupIndex
                          )
                        }
                      >
                        <SearchOutlined />
                        <span className="tooltip">상세 정보 보기</span>
                      </span>
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleProjectPreview(
                              e,
                              group.items[0].id,
                              group.title,
                              "price_calculation"
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                    </div>
                    <label
                      className="checkbox-wrapper"
                      onClick={() =>
                        handlePreview(
                          group.items[0].id,
                          "price",
                          group.title,
                          group.items[0].labelIndex,
                          groupIndex
                        )
                      }
                    >
                      <span>{group.title}</span>
                    </label>
                  </div>
                ) : (
                  <div
                    className={`citation-accordion fade-in-item ${
                      visibleItems[`price-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <div
                      className="citation-accordion-header"
                      onClick={() => toggleSection(group.title)}
                    >
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleProjectPreview(
                              e,
                              group.items[0].id,
                              group.title,
                              "price_calculation"
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                      <span style={{ display: "flex", flex: 1 }}>
                        {group.title}
                      </span>
                      <span
                        onClick={() => toggleSection(group.title)}
                        className={`arrow ${
                          expandedSections[group.title] ? "expanded" : ""
                        }`}
                      >
                        <CaretRightOutlined />
                      </span>
                    </div>
                    <div
                      className={`citation-accordion-content ${
                        expandedSections[group.title] ? "expanded" : ""
                      }`}
                    >
                      {group.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="citation-spec-item">
                          <label
                            className="checkbox-wrapper"
                            onClick={() =>
                              handlePreview(
                                item.id,
                                "price",
                                group.title,
                                item.labelIndex,
                                groupIndex
                              )
                            }
                          >
                            <span>
                              {group.title} - {item.specification}
                            </span>
                          </label>
                          <div className="citation-preview-button-wrapper">
                            <input
                              type="checkbox"
                              checked={checkedPriceCalculations.includes(
                                item.id
                              )}
                              onChange={() =>
                                handlePriceCheckboxChange(item.id)
                              }
                            />
                            <span
                              className="preview-icon-wrapper"
                              onClick={() =>
                                handlePreview(
                                  item.id,
                                  "price",
                                  group.title,
                                  item.labelIndex,
                                  groupIndex
                                )
                              }
                            >
                              <SearchOutlined />
                              <span className="tooltip">상세 정보 보기</span>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {isModalOpen && (
        <AIStatement
          statement={selectedId}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          type={selectedType}
        />
      )}
    </>
  );
};

export default StatementCitations;
