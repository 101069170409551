/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import DupLoginContext from "../../contexts/DupLoginContext";
import WithdrawalPresenter from "./WithdrawalPresenter";

interface Props {}

export default (props: Props) => {
  const [id, setId] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isCertified, setIsCertified] = useState(false);
  const dupLogin = useContext(DupLoginContext);
  const alert = useContext(AlertContext);
  const history = useHistory();

  const pwInputRef = useRef<HTMLInputElement>(null);
  const checkboxInputRef = useRef<HTMLInputElement>(null);
  const reasonInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    new Promise(async () => {
      try {
        const res = await dupLogin.checkDupLogin(() => api.getMyInfo());
        if (res?.status === 200) {
          setId(res.data.email);
          const remainDays = Math.ceil(
            (new Date(res.data.expiry_date).getTime() - new Date().getTime()) /
              (1000 * 3600 * 24)
          );
          if (remainDays > 0) {
            setExpiryDate(res.data.expiry_date.toString());
          }
        }
      } catch (error) {
        history.replace(pages.index);
      }
    });
  }, []);

  const handleCertifyClick = async (pw?: string) => {
    try {
      const res = await dupLogin.checkDupLogin(() =>
        api.checkPasswordAsync(pw || "")
      );
      if (res.data.success) {
        setIsCertified(true);
      } else if (res?.response?.status !== 401) {
        alert.showAlert("아이디/비밀번호가 맞지 않습니다.");
      }
    } catch (error: any) {
      if (error.hasOwnProperty("response")) {
        const status = error.response?.status;
        if (status === 400) {
          alert.showAlert("아이디/비밀번호가 맞지 않습니다.");
        } else {
          alert.showAlert("에러가 발생하였습니다.");
        }
      }
    }
  };

  const handleWithdrawalClick = async (isAgreed: boolean, reason?: string) => {
    if (!isAgreed) {
      alert.showAlert("주의사항에 동의를 하셔야 탈퇴가 가능합니다.");
      return;
    }
    const res = await dupLogin.checkDupLogin(() =>
      api.postWithdrawalAsync(reason)
    );
    if (res.status === 200) {
      alert.showAlert(
        "회원탈퇴가 완료되었습니다.\n지금까지 이용해주셔서 감사합니다.",
        () => {
          history.replace(pages.index);
        }
      );
    }
  };

  return (
    <section className="content">
      <h3>회원탈퇴</h3>
      <div className="round-box">
        <div className="infoBox-danger mb-4 mt-3">
          <span className="material-symbols-outlined">error</span>
          <div className="fs-sm">
            <strong>{id}</strong>의 회원탈퇴를 진행합니다.
          </div>
        </div>
        <form
          className="row col-md-6 mx-auto g-3 needs-validation"
        >
          <div className="col-md-12">
            <label htmlFor="Inputname" className="form-label">
              현재 비밀번호
            </label>
            <input
              type="password"
              className="form-control"
              id="Inputname"
              placeholder="사용중인 비밀번호를 입력해주세요"
            />
            <div className="invalid-feedback">
              사용중인 비밀번호를 입력해주세요.
            </div>
          </div>
          <div className="col-md-12">
            <div className="SubTitle3 text-dark">유의사항</div>
            <div className="out-notice">
              <ul>
                <li>
                  회원탈퇴 시 개인정보 및 "콘엑스" 에서 만들어진 모든 데이터는
                  삭제됩니다.
                </li>
                <li>결제 내역이 모두 사라집니다. </li>
                <li>회원 내역을 복구할 수 없습니다. </li>
                <li>탈퇴한 이메일로 즉시 재가입이 불가능합니다.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="exampleFormControlTextarea1" className="form-label">
                탈퇴사유
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                placeholder="ex)사용빈도가 낮아서, 이용이 불편하고 장애가 많아서"
                ref={reasonInputRef}
              ></textarea>
            </div>
            <div className="invalid-feedback">필수 입력 사항입니다.</div>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              ref={checkboxInputRef}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              회원탈퇴 주의사항에 동의합니다.
            </label>
          </div>
          <div className="col-12 col-md-6 mx-auto pb-3">
            <button
              className="btn btn-xlg btn-danger w-100"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#dialogs"
              onClick={() => {
                handleCertifyClick(pwInputRef.current?.value || "");
                handleWithdrawalClick(
                  checkboxInputRef.current?.checked || false,
                  reasonInputRef.current?.value
                );
              }}
            >
              회원 탈퇴
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
